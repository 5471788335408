// src/layouts/FrontLayoutWithScroll.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import ScrollToTopButton from "../components/ScrollToTopButton";
import styled from "styled-components";
import Footer from "../components/Footer";

const ContentWrapper = styled.div`
  padding-top: 80px; /* ヘッダーの高さ分の余白を確保 */
`;

const FrontLayoutWithScroll: React.FC = () => {
  return (
    <>
      <Header />
      <ContentWrapper>
        <Outlet />
        <ScrollToTopButton />
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default FrontLayoutWithScroll;
