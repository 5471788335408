// src/pages/PopularShows/PopularShows.tsx
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import { FaSpotify } from "react-icons/fa";
import {
  Container,
  Title,
  Card,
  Thumbnail,
  Details,
  ShowName,
  ShowDescription,
  SpotifyButton,
  IconContainer,
  TextContainer,
  MedalBadge,
} from "./PopularShows.styles";
import { fetchPopularShows, Show } from "../../services/publicShowService";

const SpotifyIcon = FaSpotify as unknown as React.FC<{ size?: number }>;

const PopularShows: React.FC = () => {
  const [popularShows, setPopularShows] = useState<Show[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // IntersectionObserver 用の ref
  const observer = useRef<IntersectionObserver | null>(null);

  // 最後のカードが表示されたタイミングで次のページを読み込む
  const lastShowElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const loadPopularShows = async () => {
      try {
        setLoading(true);
        // ページ番号とページサイズを指定して人気番組を取得
        const data = await fetchPopularShows(page, 5);
        // 既存の popularShows に対して、重複するものを除外して連結
        setPopularShows((prev) => {
          const newShows = data.results.filter(
            (newShow) => !prev.some((existing) => existing.id === newShow.id)
          );
          return [...prev, ...newShows];
        });
        setHasMore(data.next !== null);
      } catch (err: any) {
        setError(err.message || "人気番組の取得に失敗しました");
      } finally {
        setLoading(false);
      }
    };
    loadPopularShows();
  }, [page]);

  if (error) {
    return <Container>Error: {error}</Container>;
  }

  return (
    <Container>
      <Title>人気の番組</Title>
      {popularShows.map((show, index) => {
        const cardContent = (
          <Card
            ref={index === popularShows.length - 1 ? lastShowElementRef : null}
          >
            <Thumbnail src={show.thumbnail_url} alt={show.name} />
            <Details>
              <div>
                <ShowName>{show.name}</ShowName>
                <ShowDescription>{show.description}</ShowDescription>
              </div>
              {show.external_url && (
                <SpotifyButton
                  href={show.external_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconContainer>
                    <SpotifyIcon size={20} />
                  </IconContainer>
                  <TextContainer>Listen on Spotify</TextContainer>
                </SpotifyButton>
              )}
            </Details>
            {index < 3 && <MedalBadge rank={index + 1}>{index + 1}</MedalBadge>}
          </Card>
        );
        return (
          <Link
            key={show.id}
            to={`/shows/${show.id}`}
            style={{ textDecoration: "none" }}
          >
            {cardContent}
          </Link>
        );
      })}
      {loading && (
        <LoadingIndicator isFullPage={false} color="primary" size={40} />
      )}
    </Container>
  );
};

export default PopularShows;
