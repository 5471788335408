// src/pages/AdminRankings/AdminRankings.tsx
import React, { useEffect, useState } from "react";
import {
  Container,
  RankingCard,
  RankingTitle,
  Creator,
  LikeCount,
  RankingTags,
  Tag,
  LoadMoreButton,
} from "./AdminRankings.styles";
import {
  fetchAdminRankings,
  deleteAdminRanking,
  Ranking,
} from "../../services/adminRankingService";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

const AdminRankings: React.FC = () => {
  const [rankings, setRankings] = useState<Ranking[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  // 削除モーダル用の state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRanking, setSelectedRanking] = useState<Ranking | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const loadRankings = async () => {
    setLoading(true);
    try {
      const data = await fetchAdminRankings(page, 10);
      if (data.length < 10) {
        setHasMore(false);
      }
      setRankings((prev) => [...prev, ...data]);
      setPage(page + 1);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRankings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 削除モーダルを開く
  const openDeleteModal = (ranking: Ranking) => {
    setSelectedRanking(ranking);
    setIsModalOpen(true);
  };

  // モーダルを閉じる
  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setSelectedRanking(null);
  };

  // ランキング削除処理
  const handleDelete = async () => {
    if (!selectedRanking) return;
    setDeleteLoading(true);
    try {
      await deleteAdminRanking(selectedRanking.id);
      setRankings((prev) => prev.filter((r) => r.id !== selectedRanking.id));
    } catch (err) {
      console.error(err);
      // エラー通知等を追加するとより良いでしょう
    } finally {
      setDeleteLoading(false);
      closeDeleteModal();
    }
  };

  return (
    <Container>
      {rankings.map((ranking) => (
        <RankingCard key={ranking.id}>
          <RankingTitle>{ranking.title}</RankingTitle>
          <Creator>by {ranking.creator_user}</Creator>
          <LikeCount>{ranking.like_count_cache} likes</LikeCount>
          {ranking.ranking_tags && ranking.ranking_tags.length > 0 && (
            <RankingTags>
              {ranking.ranking_tags.map((rt) => (
                <Tag key={rt.id}>#{rt.tag.name}</Tag>
              ))}
            </RankingTags>
          )}
          <p>{ranking.description}</p>
          <p>作成日: {new Date(ranking.created_at).toLocaleDateString()}</p>
          <Button bgColor="danger" onClick={() => openDeleteModal(ranking)}>
            削除
          </Button>
        </RankingCard>
      ))}
      {hasMore && (
        <LoadMoreButton onClick={loadRankings} disabled={loading}>
          {loading ? "Loading..." : "もっと見る"}
        </LoadMoreButton>
      )}
      <Modal isOpen={isModalOpen} onClose={closeDeleteModal}>
        <h2>ランキング削除の確認</h2>
        <p>
          本当にランキング {selectedRanking?.title} を削除してもよろしいですか？
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button onClick={closeDeleteModal}>キャンセル</Button>
          <Button
            bgColor="danger"
            onClick={handleDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? "削除中..." : "削除"}
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default AdminRankings;
