// src/routes/AdminRoute.tsx

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import LoadingIndicator from "../components/LoadingIndicator";

interface AdminRouteProps {
  children: React.ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  // デバッグ出力

  // ユーザー情報をまだロード中ならローディングUIを表示
  if (loading) {
    return <LoadingIndicator isFullPage={true} size={80} />;
  }

  // user が null か、あるいは is_staff でない場合は NotFound(=404)へ
  if (!user || !user.is_staff) {
    return <Navigate to="*" />;
  }

  // 管理者なら子要素を表示
  return <>{children}</>;
};

export default AdminRoute;
