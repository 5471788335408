// src/pages/Sidebar/Sidebar.tsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  SidebarContainer,
  SectionTitle,
  ItemList,
  Item,
  ThumbnailWrapper,
  Thumbnail,
  MedalBadge,
  ItemTitle,
  ItemContent,
} from "./Sidebar.styles";
import {
  fetchPopularShows,
  fetchPopularEpisodes,
  Show,
  Episode,
} from "../../services/publicShowService";
import AdSlot from "../../components/AdSlot";

const Sidebar: React.FC = () => {
  const [popularShows, setPopularShows] = useState<Show[]>([]);
  const [popularEpisodes, setPopularEpisodes] = useState<Episode[]>([]);

  useEffect(() => {
    const loadPopularShows = async () => {
      try {
        // fetchPopularShows() の戻り値から results を取り出す
        const { results } = await fetchPopularShows();
        setPopularShows(results);
      } catch (error) {
        console.error(error);
      }
    };
    loadPopularShows();
  }, []);

  useEffect(() => {
    const loadPopularEpisodes = async () => {
      try {
        const episodes = await fetchPopularEpisodes();
        setPopularEpisodes(episodes);
      } catch (error) {
        console.error(error);
      }
    };
    loadPopularEpisodes();
  }, []);

  return (
    <>
      <AdSlot slot="sidebar" adIndex={0} />
      <SidebarContainer>
        <SectionTitle>人気の番組</SectionTitle>
        <ItemList>
          {popularShows.map((show, index) => (
            <Item key={show.id}>
              <Link to={`/shows/${show.id}`}>
                <ItemContent>
                  <ThumbnailWrapper>
                    <Thumbnail src={show.thumbnail_url} alt={show.name} />
                    {index < 3 && (
                      <MedalBadge rank={index + 1}>{index + 1}</MedalBadge>
                    )}
                  </ThumbnailWrapper>
                  <ItemTitle>{show.name}</ItemTitle>
                </ItemContent>
              </Link>
            </Item>
          ))}
        </ItemList>
        <SectionTitle>人気のエピソード</SectionTitle>
        <ItemList>
          {popularEpisodes.map((episode, index) => (
            <Item key={episode.id}>
              <Link
                to={`/shows/${(episode as any).show_id || 0}#episode-${
                  episode.id
                }`}
              >
                <ItemContent>
                  <ThumbnailWrapper>
                    <Thumbnail src={episode.thumbnail_url} alt={episode.name} />
                    {index < 3 && (
                      <MedalBadge rank={index + 1}>{index + 1}</MedalBadge>
                    )}
                  </ThumbnailWrapper>
                  <ItemTitle>{episode.name}</ItemTitle>
                </ItemContent>
              </Link>
            </Item>
          ))}
        </ItemList>
      </SidebarContainer>
      <AdSlot slot="sidebar" adIndex={1} />
    </>
  );
};

export default Sidebar;
