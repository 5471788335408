// src/routes/PrivateRoute.tsx
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import LoadingIndicator from "../components/LoadingIndicator";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return <LoadingIndicator isFullPage={true} color="primary" size={80} />;
  }

  // ログインしていなければログインページへリダイレクト
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // ログイン済みならそのまま子要素を表示
  return <>{children}</>;
};

export default PrivateRoute;
