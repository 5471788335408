// src/components/NotificationBar.tsx
import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

interface NotificationBarProps {
  /** 通知のメッセージ */
  message: string;
  /** 背景色: theme key or custom color code */
  bgColor?: string;
  /** 文字色: theme key or custom color code */
  textColor?: string;
  /** 通知を表示するかどうか */
  isOpen: boolean;
  /** 通知を閉じるイベント */
  onClose: () => void;
  /** 通知が表示されてから自動的に閉じるまでの時間(ミリ秒) */
  duration?: number;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  message,
  bgColor,
  textColor,
  isOpen,
  onClose,
  duration = 10000, // デフォルト10秒
}) => {
  // ローカル状態で表示・非表示を管理
  const [visible, setVisible] = useState(isOpen);

  // 親コンポーネントからの isOpen の変化に合わせて状態を更新
  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  // handleClose を useCallback でラップし、onClose を依存に入れることで参照を安定化
  const handleClose = useCallback(() => {
    setVisible(false);
    onClose();
  }, [onClose]);

  // visible が true の場合に duration 後に自動的に閉じるタイマーをセット
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (visible) {
      timer = setTimeout(() => {
        handleClose();
      }, duration);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [visible, duration, handleClose]);

  return (
    <BarContainer
      bgColor={bgColor}
      textColor={textColor}
      style={{ transform: visible ? "translateY(0%)" : "translateY(-100%)" }}
    >
      <Message>{message}</Message>
      <CloseButton onClick={handleClose}>×</CloseButton>
    </BarContainer>
  );
};

export default NotificationBar;

// styled-components
const BarContainer = styled.div<
  Pick<NotificationBarProps, "bgColor" | "textColor">
>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? (theme.colors as any)[bgColor] || bgColor : "#333"};
  color: ${({ theme, textColor }) =>
    textColor ? (theme.colors as any)[textColor] || textColor : "#fff"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
`;

const Message = styled.span`
  font-size: 1rem;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: inherit;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
