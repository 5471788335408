import React from "react";
import {
  Container,
  Title,
  Section,
  Paragraph,
  List,
} from "./PrivacyPolicy.styles";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Title>プライバシーポリシー</Title>

      <Section>
        <Paragraph>
          本プライバシーポリシーは、Emine（笑音）（以下「本サービス」といいます。）が提供するサービスにおいて、ユーザーの個人情報の取り扱いについて定めるものです。本サービスをご利用される全ての方は、本ポリシーに同意されたものとみなされます。
        </Paragraph>
      </Section>

      <Section>
        <h2>1. 取得する情報</h2>
        <Paragraph>
          本サービスでは、以下の情報を取得・保存する場合があります。
        </Paragraph>
        <List>
          <li>
            ユーザーがランキング作成や「いいね」を行う際に生成される匿名ID
          </li>
          <li>アクセス時のIPアドレス（ハッシュ化して保存）</li>
          <li>ブラウザのクッキーやローカルストレージに保存される情報</li>
          <li>Spotify APIより取得する番組やエピソードの情報</li>
        </List>
      </Section>

      <Section>
        <h2>2. 利用目的</h2>
        <Paragraph>取得した情報は、以下の目的のために利用されます。</Paragraph>
        <List>
          <li>ランキング作成、表示、検索、共有のため</li>
          <li>ユーザーの重複登録防止やボット対策のため</li>
          <li>サービスの改善や新機能の開発のための分析</li>
          <li>Spotify APIを利用したコンテンツの表示および更新のため</li>
        </List>
      </Section>

      <Section>
        <h2>3. 情報の第三者提供</h2>
        <Paragraph>
          ユーザーの個人を特定できる情報を、第三者に提供することは基本的にありません。ただし、法令に基づく場合やユーザーの同意がある場合を除きます。
        </Paragraph>
      </Section>

      <Section>
        <h2>4. クッキーおよびローカルストレージ</h2>
        <Paragraph>
          本サービスでは、ユーザーの利便性向上および不正アクセス防止のために、クッキーやローカルストレージを利用しています。これらの情報は、匿名IDの管理などに使用されます。
        </Paragraph>
      </Section>

      <Section>
        <h2>5. セキュリティ</h2>
        <Paragraph>
          本サービスは、ユーザー情報の安全管理のため、必要かつ適切な措置を講じています。しかし、インターネット上の情報伝送の安全性を完全に保証するものではありません。
        </Paragraph>
      </Section>

      <Section>
        <h2>6. 未成年者のプライバシー</h2>
        <Paragraph>
          本サービスは、原則として18歳未満の方の利用を想定しておりません。未成年者が本サービスを利用する場合は、保護者の同意が必要となります。
        </Paragraph>
      </Section>

      <Section>
        <h2>7. プライバシーポリシーの変更</h2>
        <Paragraph>
          本プライバシーポリシーは、必要に応じて変更されることがあります。変更後のポリシーは、本サービス上に掲載された時点で効力を生じるものとします。
        </Paragraph>
      </Section>

      <Section>
        <h2>8. お問い合わせ</h2>
        <Paragraph>
          本プライバシーポリシーに関するお問い合わせは、本サービスのお問い合わせ窓口までご連絡ください。
        </Paragraph>
      </Section>

      <Section>
        <Paragraph>（以上）</Paragraph>
      </Section>
    </Container>
  );
};

export default PrivacyPolicy;
