import React from "react";
import { authService } from "../../services/authService";
import { Container, Title, ButtonWrapper } from "./Register.styles";
import SocialButton from "../../components/SocialButton";

const Register: React.FC = () => {
  const handleSocialLogin = (provider: "google" | "line" | "spotify") => {
    // ソーシャルログイン用のURLへリダイレクト
    window.location.href = authService.getSocialLoginUrl(provider);
  };

  return (
    <Container>
      <Title>新規登録</Title>
      <ButtonWrapper>
        <SocialButton
          provider="google"
          onClick={() => handleSocialLogin("google")}
        >
          Googleで登録
        </SocialButton>
      </ButtonWrapper>
      <ButtonWrapper>
        <SocialButton provider="line" onClick={() => handleSocialLogin("line")}>
          LINEで登録
        </SocialButton>
      </ButtonWrapper>
      <ButtonWrapper>
        <SocialButton
          provider="spotify"
          onClick={() => handleSocialLogin("spotify")}
        >
          Spotifyで登録
        </SocialButton>
      </ButtonWrapper>
    </Container>
  );
};

export default Register;
