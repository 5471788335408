// src/services/authService.ts
import { v4 as uuidv4 } from "uuid";
import { apiClient } from "./apiClient";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const AUTH_API_URL = `${API_BASE_URL}/api/auth`;

/** ログイン / レジスターでサーバーが返す想定レスポンス */
export interface AuthResponse {
  access: string;
  refresh: string;
  user: any;
}

export const authService = {
  /**
   * 匿名ユーザーIDを localStorage から取得・なければ生成
   * ※キーを "anonymous_uuid" に統一
   */
  getAnonymousId: (): string => {
    let anonymousId = localStorage.getItem("anonymous_uuid");
    if (!anonymousId) {
      anonymousId = uuidv4();
      localStorage.setItem("anonymous_uuid", anonymousId);
    }
    return anonymousId;
  },

  /**
   * ログイン (メール/パスワードなどの一般ログイン)
   */
  login: async (username: string, password: string): Promise<AuthResponse> => {
    const res = await fetch(`${AUTH_API_URL}/login/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });
    if (!res.ok) {
      throw new Error("Login failed");
    }
    const data = await res.json(); // => { access, refresh, user }
    localStorage.setItem("auth_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
    return data;
  },

  /**
   * 新規登録
   */
  register: async (
    username: string,
    password: string
  ): Promise<AuthResponse> => {
    const res = await fetch(`${AUTH_API_URL}/register/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });
    if (!res.ok) {
      throw new Error("Registration failed");
    }
    const data = await res.json(); // => { access, refresh, user }
    localStorage.setItem("auth_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
    return data;
  },

  /**
   * ソーシャルログイン用のリダイレクトURLを返す
   */
  getSocialLoginUrl: (provider: "google" | "line" | "spotify"): string => {
    return `${AUTH_API_URL}/social/${provider}`;
  },

  /**
   * 現在ログイン中のユーザー情報を取得
   */
  getCurrentUser: async () => {
    const url = `${AUTH_API_URL}/me/`;
    const response = await apiClient(url, { method: "GET" });
    if (!response.ok) {
      throw new Error("Failed to get current user");
    }

    const data = await response.json();
    // サーバーが毎回 access, refresh を返す設計なら、ここでローカル更新
    if (data.access && data.refresh) {
      localStorage.setItem("auth_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
    }
    return data;
  },

  /**
   * ユーザー情報を更新 (例: username のみ更新)
   */
  updateCurrentUser: async (payload: Partial<{ username: string }>) => {
    const url = `${AUTH_API_URL}/me/`;
    const response = await apiClient(url, {
      method: "PATCH",
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to update current user");
    }
    return response.json();
  },

  /**
   * ユーザーアカウント削除
   */
  deleteCurrentUser: async () => {
    const url = `${AUTH_API_URL}/me/`;
    const response = await apiClient(url, { method: "DELETE" });
    if (!response.ok) {
      throw new Error("Failed to delete current user");
    }
    return response.json();
  },

  /**
   * ログアウト
   */
  logout: () => {
    localStorage.removeItem("anonymous_uuid");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refresh_token");
  },
};
