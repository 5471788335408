import styled from "styled-components";

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
`;

export const Section = styled.section`
  margin-bottom: 5rem;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.secondary || "#f5f5f5"};
  color: ${({ theme }) => theme.colors.text || "#000"};
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;
