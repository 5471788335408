// ContactForm.tsx
import React, { useState } from "react";
import axios from "axios";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react19-google-recaptcha-v3";
import {
  FormContainer,
  FormField,
  Label,
  Input,
  TextArea,
  Button,
  ErrorMessage,
  SuccessMessage,
} from "./ContactForm.styles";

// 環境変数からサイトキーを取得（必ず string として扱う）
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

const ContactFormContent: React.FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // 入力チェック
    if (!name || !email || !subject || !message) {
      setError("全てのフィールドを入力してください。");
      return;
    }

    // executeRecaptcha がまだ利用できなければエラー
    if (!executeRecaptcha) {
      setError("reCAPTCHAがまだ準備できていません。");
      return;
    }

    try {
      // フォーム送信時にトークンを取得
      const token = await executeRecaptcha("contact_form");
      if (!token) {
        setError("reCAPTCHAの検証に失敗しました。");
        return;
      }

      // バックエンドへリクエスト
      const response = await axios.post(
        `${API_BASE_URL}/api/contact/`,
        { name, email, subject, message, recaptcha_token: token },
        { withCredentials: true }
      );

      if (response.status === 201) {
        setSuccess("お問い合わせを送信しました。");
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
      }
    } catch (err: any) {
      setError("お問い合わせの送信に失敗しました。");
    }
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
        <FormField>
          <Label>お名前</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormField>
        <FormField>
          <Label>メールアドレス</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormField>
        <FormField>
          <Label>件名</Label>
          <Input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </FormField>
        <FormField>
          <Label>メッセージ</Label>
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            rows={5}
          />
        </FormField>
        <Button type="submit">送信</Button>
      </form>
    </FormContainer>
  );
};

const ContactForm: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <ContactFormContent />
    </GoogleReCaptchaProvider>
  );
};

export default ContactForm;
