// src/hooks/useDeviceType.ts
import { useState, useEffect } from "react";

export default function useDeviceType(): "desktop" | "mobile" {
  const [deviceType, setDeviceType] = useState<"desktop" | "mobile">("desktop");

  useEffect(() => {
    const handleResize = () => {
      // 例として、幅768px未満ならモバイルとする
      setDeviceType(window.innerWidth < 1024 ? "mobile" : "desktop");
    };

    window.addEventListener("resize", handleResize);
    // 初期チェック
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return deviceType;
}
