// src/components/ScrollToTopButton.tsx
import React, { useState, useEffect } from "react";
import styled from "styled-components";

// ボタンを丸くするために clip-path を削除し、border-radius: 50% を追加
const Button = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: #1db954;
  border: none;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0.2, 0.2, 0.5);
  z-index: 1000;
  opacity: 0.8;
  transition: opacity 0.3s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
`;

// 矢印アイコンをチェブロン（底辺なし三角形）に変更
const ArrowIcon: React.FC = () => (
  <svg viewBox="0 0 24 24" width="24" height="24">
    <polyline
      points="6 15 12 9 18 15"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  // スクロール位置が 200px を超えたら表示
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // スムーズスクロールでトップへ戻る
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {isVisible && (
        <Button onClick={scrollToTop}>
          <ArrowIcon />
        </Button>
      )}
    </>
  );
};

export default ScrollToTopButton;
