import React, { useState, useEffect, useRef, KeyboardEvent } from "react";
import {
  Container,
  Section,
  Input,
  TextArea,
  EpisodeList,
  EpisodeItem,
  SearchResultsContainer,
  ShowItem,
  ShowThumbnail,
  ShowInfo,
  ShowTitle,
  ShowDescription,
  TagContainer,
  TagItem,
  TagRemoveButton,
  ContentTitle,
  ModalTitle,
  CloseButton, // CloseButton はsticky設定済み（top等はヘッダー分調整済み）
} from "./RankingCreation.styles";
import {
  fetchPublicShows,
  Show,
  fetchPublicEpisodes,
  Episode,
} from "../../services/publicShowService";
import { createRanking, RankingEpisode } from "../../services/rankingService";
import { RankingCreatePayload } from "../../types/ranking";
import { FaPlus, FaMinus } from "react-icons/fa";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

const PlusIcon = FaPlus as React.FC<{ style?: React.CSSProperties }>;
const MinusIcon = FaMinus as React.FC<{ style?: React.CSSProperties }>;

// 禁止語リスト（例）— 部分一致でチェックするため単語のみ記述
const bannedWords = ["死ね", "しね", "まんこ", "ちんこ"];

const containsBannedWord = (text: string): boolean => {
  // 大文字小文字の区別は日本語では不要ですが、英語の場合にも対応
  return bannedWords.some((word) =>
    text.toLowerCase().includes(word.toLowerCase())
  );
};

const RankingCreation: React.FC = () => {
  // ランキング基本情報
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // タグ入力用
  const [newTag, setNewTag] = useState("");
  const [tagList, setTagList] = useState<string[]>([]);
  const [isComposing, setIsComposing] = useState(false);

  // 番組検索関連
  const [searchQuery, setSearchQuery] = useState("");
  const [showResults, setShowResults] = useState<Show[]>([]);
  const [selectedShow, setSelectedShow] = useState<Show | null>(null);
  const [episodes, setEpisodes] = useState<Episode[]>([]);

  // ランキングに追加するエピソード
  const [rankingEpisodes, setRankingEpisodes] = useState<RankingEpisode[]>([]);

  // フォーム送信時のエラーメッセージ
  const [formError, setFormError] = useState<string | null>(null);

  // モーダル表示用
  const [isModalOpen, setIsModalOpen] = useState(false);

  // デバウンス＋最新リクエスト管理用
  const latestQueryId = useRef(0);

  // 番組検索：searchQuery変更時にAPI呼出（300msデバウンス・最新の結果のみ反映）
  useEffect(() => {
    latestQueryId.current++;
    const currentQueryId = latestQueryId.current;
    const handler = setTimeout(() => {
      if (searchQuery.trim() !== "") {
        fetchPublicShows(1, 20, searchQuery, "-created_at")
          .then((results) => {
            if (currentQueryId === latestQueryId.current) {
              setShowResults(results);
            }
          })
          .catch((error) => console.error(error));
      } else {
        setShowResults([]);
      }
    }, 300);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  // searchQuery変更時に選択中の番組とエピソードをクリア
  useEffect(() => {
    setSelectedShow(null);
    setEpisodes([]);
  }, [searchQuery]);

  // 番組クリック時：選択した番組とそのエピソード一覧を取得
  const handleShowClick = async (show: Show) => {
    setSelectedShow(show);
    try {
      const eps = await fetchPublicEpisodes(show.id);
      setEpisodes(eps);
    } catch (error) {
      console.error(error);
      setEpisodes([]);
    }
  };

  // エピソード追加
  const addEpisode = (episode: Episode) => {
    setRankingEpisodes((prev) => {
      if (prev.some((ep) => ep.episode.id === episode.id)) return prev;
      return [
        ...prev,
        { id: episode.id, order_index: prev.length + 1, episode: episode },
      ];
    });
  };

  // エピソード削除
  const removeEpisode = (episodeId: number) => {
    setRankingEpisodes((prev) =>
      prev.filter((ep) => ep.episode.id !== episodeId)
    );
  };

  // タグ追加（Enterキー押下時）
  const handleTagKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !isComposing) {
      e.preventDefault();
      const tag = newTag.trim();
      if (tag && !tagList.includes(tag)) {
        setTagList((prev) => [...prev, tag]);
      }
      setNewTag("");
    }
  };

  // タグ削除
  const removeTag = (tagToRemove: string) => {
    setTagList((prev) => prev.filter((tag) => tag !== tagToRemove));
  };

  // 匿名ユーザー用UUID取得
  function getOrCreateAnonymousUUID(): string {
    let anonUuid = localStorage.getItem("anonymous_uuid");
    if (!anonUuid) {
      anonUuid = crypto.randomUUID();
      localStorage.setItem("anonymous_uuid", anonUuid);
    }
    return anonUuid;
  }

  // 入力検証：タイトル、説明、タグに禁止語が含まれていないかチェック
  const validateInput = (): boolean => {
    if (containsBannedWord(title)) {
      setFormError("タイトルに不適切な言葉が含まれています。");
      return false;
    }
    if (containsBannedWord(description)) {
      setFormError("説明に不適切な言葉が含まれています。");
      return false;
    }
    if (tagList.some((tag) => containsBannedWord(tag))) {
      setFormError("タグに不適切な言葉が含まれています。");
      return false;
    }
    return true;
  };

  // ランキング作成 API 呼び出し
  const handleSubmit = async () => {
    if (!title.trim()) {
      setFormError("タイトルが入力されていません。");
      return;
    }
    if (rankingEpisodes.length === 0) {
      setFormError("番組は1つ以上選択してください。");
      return;
    }
    // 入力検証
    if (!validateInput()) return;

    setFormError(null);
    const payload: RankingCreatePayload = {
      title,
      description,
      ranking_episodes: rankingEpisodes.map((ep) => ({
        episode_id: ep.episode.id,
        order_index: ep.order_index,
      })),
      ranking_tags: tagList,
      anonymous_uuid: getOrCreateAnonymousUUID(),
    };
    try {
      await createRanking(payload);
      alert("Ranking created successfully!");
      // フォームリセット
      setTitle("");
      setDescription("");
      setTagList([]);
      setRankingEpisodes([]);
      setSearchQuery("");
      setShowResults([]);
      setSelectedShow(null);
      setEpisodes([]);
      setFormError(null);
    } catch (error: any) {
      console.error(error);
      setFormError(error.message ?? "Failed to create ranking.");
    }
  };

  // ランキング作成ボタン押下時
  const handleRankingButtonClick = () => {
    if (!title.trim()) {
      setFormError("タイトルが入力されていません。");
      return;
    }
    if (rankingEpisodes.length === 0) {
      setFormError("番組は1つ以上選択してください。");
      return;
    }
    setFormError(null);
    setIsModalOpen(true);
  };

  // モーダル内 OK ボタン
  const handleModalOk = async () => {
    await handleSubmit();
    setIsModalOpen(false);
  };

  // エピソード一覧を閉じる（stickyなCloseButton）
  const handleCloseEpisodes = () => {
    setSelectedShow(null);
    setEpisodes([]);
  };

  return (
    <Container>
      <h1>ランキング作成</h1>
      <Section>
        <Input
          type="text"
          placeholder="ランキングタイトル"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextArea
          placeholder="ランキングの説明"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Input
          type="text"
          placeholder="タグ (エンターで追加)"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          onKeyDown={handleTagKeyDown}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <TagContainer>
          {tagList.map((tag) => (
            <TagItem key={tag}>
              #{tag}
              <TagRemoveButton onClick={() => removeTag(tag)}>
                ×
              </TagRemoveButton>
            </TagItem>
          ))}
        </TagContainer>
      </Section>
      <Section>
        <ContentTitle>番組検索</ContentTitle>
        <Input
          type="text"
          placeholder="検索したい番組のキーワードを入力"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {showResults.length > 0 && (
          <SearchResultsContainer>
            {showResults.map((show) => (
              <ShowItem key={show.id} onClick={() => handleShowClick(show)}>
                <ShowThumbnail src={show.thumbnail_url} alt={show.name} />
                <ShowInfo>
                  <ShowTitle>{show.name}</ShowTitle>
                  <ShowDescription>{show.description}</ShowDescription>
                </ShowInfo>
              </ShowItem>
            ))}
          </SearchResultsContainer>
        )}
      </Section>
      {selectedShow && (
        <Section>
          <h2>Episodes for {selectedShow.name}</h2>
          {/* stickyなCloseButton */}
          <CloseButton onClick={handleCloseEpisodes}>
            Close Episodes
          </CloseButton>
          {episodes.length > 0 ? (
            <EpisodeList>
              {episodes.map((ep) => {
                const isAdded = rankingEpisodes.some(
                  (item) => item.episode.id === ep.id
                );
                return (
                  <EpisodeItem key={ep.id}>
                    <span>
                      {ep.name} ({ep.release_date})
                    </span>
                    {isAdded ? (
                      <Button
                        onClick={() => removeEpisode(ep.id)}
                        bgColor="danger"
                        textColor="white"
                        rounded={true}
                      >
                        <MinusIcon style={{ marginRight: "0.5rem" }} />
                        Remove
                      </Button>
                    ) : (
                      <Button onClick={() => addEpisode(ep)} rounded={true}>
                        <PlusIcon style={{ marginRight: "0.5rem" }} />
                        Add
                      </Button>
                    )}
                  </EpisodeItem>
                );
              })}
            </EpisodeList>
          ) : (
            <p>検索結果がありません。</p>
          )}
        </Section>
      )}
      <Section>
        <ContentTitle>追加したエピソード</ContentTitle>
        {rankingEpisodes.length > 0 ? (
          <EpisodeList>
            {rankingEpisodes.map((ep) => (
              <EpisodeItem key={ep.episode.id}>
                <span>エピソード名: {ep.episode.name}</span>
                <Button
                  onClick={() => removeEpisode(ep.episode.id)}
                  bgColor="danger"
                  textColor="white"
                  rounded={true}
                >
                  <MinusIcon style={{ marginRight: "0.5rem" }} />
                  Remove
                </Button>
              </EpisodeItem>
            ))}
          </EpisodeList>
        ) : (
          <p>エピソードが追加されていません。</p>
        )}
      </Section>
      {formError && (
        <div style={{ color: "red", marginBottom: "1rem" }}>{formError}</div>
      )}
      <Button onClick={handleRankingButtonClick}>ランキング作成</Button>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalTitle>選択されたエピソードの確認</ModalTitle>
        {rankingEpisodes.length > 0 ? (
          <EpisodeList>
            {rankingEpisodes.map((ep) => (
              <EpisodeItem key={ep.episode.id}>
                <span>
                  {ep.episode.name} ({ep.episode.release_date})
                </span>
              </EpisodeItem>
            ))}
          </EpisodeList>
        ) : (
          <p>エピソードが追加されていません。</p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button onClick={handleModalOk}>OK</Button>
          <Button onClick={() => setIsModalOpen(false)} bgColor="danger">
            キャンセル
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default RankingCreation;
