// src/pages/ShowEpisodes/ShowEpisodesPage.tsx
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  fetchPublicEpisodes,
  fetchPublicShow, // 番組詳細を取得するための関数
  Episode,
  Show,
} from "../../services/publicShowService";
import {
  Container,
  SearchContainer,
  SearchInput,
  ToggleSortButton,
  EpisodeCard,
  EpisodeImage,
  EpisodeDetails,
  EpisodeTitle,
  EpisodeDescription,
  EpisodeReleaseDate, // 追加：リリース日表示用のコンポーネント
  LoadingText,
  ErrorText,
  ShowInfoContainer,
  ShowThumbnail,
  ShowTextContainer,
  ShowName,
  ShowDescriptionText,
} from "./ShowEpisodes.styles";
import styled from "styled-components";
import { FaSpotify } from "react-icons/fa";

const PAGE_SIZE = 10;

type SortOrder = "new" | "old";

const SpotifyIcon = FaSpotify as unknown as React.FC<{ size?: number }>;

// Spotify ボタンのスタイル
const StyledSpotifyButton = styled.a`
  border: 1px solid #ccc;
  width: 260px;
  height: 28px;
  margin-top: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1db954;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #17a74a;
  }
`;

const IconContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
`;

const TextContainer = styled.span`
  flex: 1;
  text-align: left;
  padding-left: 0.5rem;
`;

const SpotifyButton: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, ...props }) => {
  return (
    <StyledSpotifyButton {...props}>
      <IconContainer>
        <SpotifyIcon size={20} />
      </IconContainer>
      <TextContainer>{children}</TextContainer>
    </StyledSpotifyButton>
  );
};

const ShowEpisodesPage: React.FC = () => {
  const { showId } = useParams<{ showId: string }>();
  const location = useLocation();

  const [show, setShow] = useState<Show | null>(null);
  const [fullEpisodes, setFullEpisodes] = useState<Episode[]>([]);
  const [displayedEpisodes, setDisplayedEpisodes] = useState<Episode[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("new");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const observer = useRef<IntersectionObserver | null>(null);
  const [page, setPage] = useState<number>(1);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  // 番組詳細情報の取得
  useEffect(() => {
    if (!showId) return;
    fetchPublicShow(Number(showId))
      .then((data) => {
        setShow(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [showId]);

  // エピソードの取得
  useEffect(() => {
    if (!showId) return;
    setLoading(true);
    fetchPublicEpisodes(Number(showId))
      .then((data) => {
        setFullEpisodes(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError("エピソードの取得に失敗しました。");
        setLoading(false);
      });
  }, [showId]);

  // 検索とソートの処理
  const filteredSortedEpisodes = React.useMemo(() => {
    let episodes = [...fullEpisodes];
    if (searchQuery) {
      const q = searchQuery.toLowerCase();
      episodes = episodes.filter(
        (ep) =>
          ep.name.toLowerCase().includes(q) ||
          ep.description.toLowerCase().includes(q)
      );
    }
    episodes.sort((a, b) => {
      const dateA = new Date(a.release_date).getTime();
      const dateB = new Date(b.release_date).getTime();
      return sortOrder === "new" ? dateB - dateA : dateA - dateB;
    });
    return episodes;
  }, [fullEpisodes, searchQuery, sortOrder]);

  // 表示リストの更新
  useEffect(() => {
    const newDisplayed = filteredSortedEpisodes.slice(0, page * PAGE_SIZE);
    setDisplayedEpisodes(newDisplayed);
    setHasMore(newDisplayed.length < filteredSortedEpisodes.length);
  }, [filteredSortedEpisodes, page]);

  // 検索・ソート変更時はページ番号リセット
  useEffect(() => {
    setPage(1);
  }, [searchQuery, sortOrder]);

  // Infinite Scroll の実装
  const lastEpisodeElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prev) => prev + 1);
          }
        },
        { rootMargin: "100px" }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // URLのハッシュに基づくページ番号の更新
  useEffect(() => {
    if (!loading && location.hash) {
      const targetId = location.hash.substring(1); // "episode-xxx"
      const targetIndex = filteredSortedEpisodes.findIndex(
        (ep) => `episode-${ep.id}` === targetId
      );
      if (targetIndex !== -1) {
        const requiredPage = Math.ceil((targetIndex + 1) / PAGE_SIZE);
        if (requiredPage > page) {
          setPage(requiredPage);
        }
      }
    }
  }, [loading, location.hash, filteredSortedEpisodes, page]);

  // URLのハッシュがある場合にスクロール
  useEffect(() => {
    if (!loading && location.hash && !hasScrolled) {
      const targetId = location.hash.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        setHasScrolled(true);
      }
    }
  }, [location.hash, loading, displayedEpisodes, hasScrolled]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <Container>
      {/* 番組詳細情報 */}
      {show && (
        <ShowInfoContainer>
          <ShowThumbnail src={show.thumbnail_url} alt={show.name} />
          <ShowTextContainer>
            <ShowName>{show.name}</ShowName>
            <ShowDescriptionText>{show.description}</ShowDescriptionText>
          </ShowTextContainer>
        </ShowInfoContainer>
      )}

      <h1>エピソード一覧</h1>
      {/* 検索フォーム */}
      <SearchContainer onSubmit={handleSearchSubmit}>
        <SearchInput
          type="text"
          placeholder="エピソード名、説明で検索..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchContainer>
      {/* ソート切替ボタン */}
      <ToggleSortButton
        onClick={() => setSortOrder((prev) => (prev === "new" ? "old" : "new"))}
      >
        {sortOrder === "new" ? "古い順に並べ替え" : "新しい順に並べ替え"}
      </ToggleSortButton>
      {/* エピソード一覧 */}
      {displayedEpisodes.map((episode, index) => {
        const episodeContent = (
          <>
            <EpisodeImage src={episode.thumbnail_url} alt={episode.name} />
            <EpisodeDetails>
              <EpisodeTitle>{episode.name}</EpisodeTitle>
              <EpisodeDescription>{episode.description}</EpisodeDescription>
              {/* Spotify へのリンク */}
              {episode.external_url && (
                <SpotifyButton
                  href={episode.external_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Listen on Spotify
                </SpotifyButton>
              )}
              {/* リリース日を右下に表示 */}
              <EpisodeReleaseDate>
                {new Date(episode.release_date).toLocaleDateString()}
              </EpisodeReleaseDate>
            </EpisodeDetails>
          </>
        );

        if (displayedEpisodes.length === index + 1) {
          return (
            <EpisodeCard
              key={episode.id}
              ref={lastEpisodeElementRef}
              id={`episode-${episode.id}`}
            >
              {episodeContent}
            </EpisodeCard>
          );
        } else {
          return (
            <EpisodeCard key={episode.id} id={`episode-${episode.id}`}>
              {episodeContent}
            </EpisodeCard>
          );
        }
      })}
      {loading && <LoadingText>読み込み中...</LoadingText>}
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default ShowEpisodesPage;
