// src/styles/theme.ts

export const theme = {
  colors: {
    // ボタンや通知などでよく使う色
    primary: "#1DB954",
    success: "#2ecc71", // 緑
    danger: "#e74c3c", // 赤
    warning: "#f39c12", // オレンジ
    info: "#3498db", // 青
    gray: "#7f8c8d", //グレー
    white: "#ffffff", // 白
    black: "#000000", // 黒
  },

  // サイズや角丸など共通設定
  borderRadius: {
    none: "0px",
    sm: "4px",
    md: "8px",
    lg: "16px",
  },
};