// src/services/publicShowService.ts
export interface Show {
  id: number;
  name: string;
  description: string;
  thumbnail_url: string;
  publisher: string;
  external_url: string;
  total_episodes: number;
  created_at: string;
  // 他に必要なフィールドがあれば追加
}

export const fetchPublicShows = async (
  page: number = 1,
  pageSize: number = 10,
  search?: string,
  ordering?: string
): Promise<Show[]> => {
  const params = new URLSearchParams();
  params.append("page", String(page));
  params.append("page_size", String(pageSize));
  if (search) params.append("search", search);
  if (ordering) params.append("ordering", ordering);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const response = await fetch(
    `${API_BASE_URL}/api/podcasts/shows/?${params.toString()}`,
    { credentials: "include" }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch shows");
  }
  const data = await response.json();
  // PageNumberPaginationの場合、data には { count, next, previous, results } が含まれる
  return data.results; // results 配列を返す
};

// src/services/publicShowService.ts
export const fetchPopularShows = async (
  page: number = 1,
  pageSize: number = 5
): Promise<{ results: Show[]; next: string | null; count: number }> => {
  const params = new URLSearchParams();
  params.append("page", String(page));
  params.append("page_size", String(pageSize));
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const response = await fetch(
    `${API_BASE_URL}/api/podcasts/shows/popular/?${params.toString()}`,
    { credentials: "include" }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch popular shows");
  }
  const data = await response.json();
  return data; // data は { results, next, count } を含む前提
};

// ★ 追加：個別の番組詳細を取得する関数
export const fetchPublicShow = async (showId: number): Promise<Show> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const response = await fetch(
    `${API_BASE_URL}/api/podcasts/shows/${showId}/`,
    { credentials: "include" }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch show");
  }
  return response.json();
};

// episodes 取得:
export interface Episode {
  id: number;
  name: string;
  description: string;
  thumbnail_url: string;
  duration_ms: number;
  release_date: string;
  external_url: string;
  created_at: string;
  show_id: number;
}

export const fetchPublicEpisodes = async (
  showId: number
): Promise<Episode[]> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const response = await fetch(
    `${API_BASE_URL}/api/podcasts/shows/${showId}/episodes/`,
    {
      credentials: "include",
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch episodes");
  }
  return response.json();
};

export const fetchPopularEpisodes = async (): Promise<Episode[]> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const response = await fetch(
    `${API_BASE_URL}/api/podcasts/episodes/popular/?page_size=5`,
    { credentials: "include" }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch popular episodes");
  }
  const data = await response.json();
  // data.results が存在すればそれを、存在しなければ data 自体を返す
  return data.results || data;
};
