import styled from "styled-components";

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
`;

export const ToggleSortButton = styled.button`
  display: inline-flex;
  align-items: center;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: 1rem;
  &:hover {
    background: #0056b3;
  }

  svg {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
`;

export const EpisodeCard = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
`;

export const EpisodeImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
`;

export const EpisodeDetails = styled.div`
  flex: 1;
`;

export const EpisodeTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: #333;
`;

export const EpisodeDescription = styled.p`
  margin: 0.5rem 0;
  color: #555;
`;

export const EpisodeLink = styled.a`
  text-decoration: none;
  width: 120px;
  &:hover {
    text-decoration: underline;
  }
`;
