// src/pages/PopularShows/PopularShowsPage.tsx
import React from "react";
import Layout from "../Layout/Layout";
import PopularShows from "./PopularShows";

const PopularShowsPage: React.FC = () => {
  return (
    <Layout>
      <PopularShows />
    </Layout>
  );
};

export default PopularShowsPage;
