// src/services/adminShowService.ts
export interface Show {
  id: number;
  spotify_show_id: string;
  name: string;
  description: string;
  thumbnail_url: string;
  publisher: string;
  external_url: string;
  total_episodes: number;
  last_spotify_update: string;
  created_at: string;
  updated_at: string;
}

export const fetchAdminShows = async (
  page: number = 1,
  pageSize: number = 10,
  search?: string,
  ordering?: string
): Promise<Show[]> => {
  const params = new URLSearchParams();
  params.append("page", String(page));
  params.append("page_size", String(pageSize));
  if (search) params.append("search", search);
  if (ordering) params.append("ordering", ordering);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const token = localStorage.getItem("auth_token");
  const response = await fetch(
    `${API_BASE_URL}/api/admin/shows/?${params.toString()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch shows");
  }
  const json = await response.json();
  return json.results; // 結果のみ返す
};

export const fetchAdminShow = async (showId: number): Promise<Show> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const response = await fetch(`${API_BASE_URL}/api/admin/shows/${showId}/`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Failed to fetch show details");
  }
  return response.json();
};

export const deleteAdminShow = async (id: number): Promise<void> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const token = localStorage.getItem("auth_token");
  const response = await fetch(
    `${API_BASE_URL}/api/admin/shows/${id}/delete/`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to delete show");
  }
};
