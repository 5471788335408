import styled from "styled-components";

/** 画面幅のブレイクポイント（例: 480px, 768pxなど） */
const MOBILE_BREAKPOINT = "480px";

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;

`;

export const RankingCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0.5rem;
  }
`;

export const RankingTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

export const RankingDiscription = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: #555;
`;

export const Creator = styled.p`
  margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #555;
`;

export const EpisodeList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0;
`;

/**
 * エピソード単位のラッパ
 */
export const EpisodeItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  height: 120px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    align-items: start;
  }
`;

export const EpisodeThumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1rem;
  cursor: pointer;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 80px;
    height: 80px;
    // margin-right: 0;
  }
`;

export const EpisodeInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    justify-content: flex-start;
    width: 65%;
  }
`;

export const EpisodeTitle = styled.span`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.3rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 0.8rem;
  }
`;

export const EpisodeShowName = styled.span`
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 0.5rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 0rem;
  }
`;

export const LikeCount = styled.p`
  font-size: 0.9rem;
  color: #888;
`;

export const RankingTags = styled.div`
  margin-top: 0.5rem;
`;

export const Tag = styled.span`
  margin-right: 0.5rem;
  color: #007bff;
  font-size: 0.9rem;
`;

/** モーダルに大きく表示する画像 */
export const ModalImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 70%;
  }
`;

/** モーダルの中身レイアウト */
export const ModalContent = styled.div`
  /* 中身のレイアウトなど自由に調整 */
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    text-align: center;
    font-size: 0.9rem;
  }
`;

export const ModalDescription = styled.p`
  color: #555;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* 表示する行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  /* 必要に応じて、他のブラウザ用プロパティを追加 */
`;
