// src/services/adminRankingService.ts
export interface RankingTag {
  id: number;
  tag: {
    id: number;
    name: string;
  };
}

export interface Ranking {
  id: number;
  title: string;
  description: string;
  like_count_cache: number;
  created_at: string;
  updated_at: string;
  creator_user: string; // 例: 作成者の表示名
  ranking_tags?: RankingTag[]; // タグ情報（オプション）
}

/**
 * 管理者用ランキング一覧を取得する関数
 * ページ番号とページサイズ、検索ワード、並び順のパラメータを渡せます。
 */
export const fetchAdminRankings = async (
  page: number = 1,
  pageSize: number = 10,
  search?: string,
  ordering?: string
): Promise<Ranking[]> => {
  const params = new URLSearchParams();
  params.append("page", String(page));
  params.append("page_size", String(pageSize));
  if (search) params.append("search", search);
  if (ordering) params.append("ordering", ordering);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const token = localStorage.getItem("auth_token");
  const response = await fetch(
    `${API_BASE_URL}/api/admin/rankings/?${params.toString()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch rankings");
  }
  const json = await response.json();
  return json.results; // ここで results プロパティのみ返す
};

export const deleteAdminRanking = async (id: number): Promise<void> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const token = localStorage.getItem("auth_token");
  const response = await fetch(`${API_BASE_URL}/api/admin/rankings/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to delete ranking");
  }
};
