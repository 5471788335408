// src/config/adsConfig.ts

interface ProviderConfig {
  code?: string;
  codeDesktop?: string;
  codeMobile?: string;
}

interface MultiAdConfig {
  activeProvider: "a8" | "google";
  providers: {
    a8: ProviderConfig;
    google: ProviderConfig;
  };
  showOnPages?: string[];
  excludedPages?: string[];
}

interface AdsConfig {
  [slot: string]: MultiAdConfig[]; // 各スロットに複数の広告設定を持たせる
}

const adsConfig: AdsConfig = {
  // サイドバーには複数の広告設定を配列で指定
  sidebar: [
    {
      activeProvider: "a8",
      providers: {
        a8: {
          codeDesktop: `
              <!-- A8サイドバー広告コード（PC用） -->
              <div class="a8-ad-container sidebar-ad-container">
                <a href="https://px.a8.net/svt/ejp?a8mat=451DZS+3SCAB6+1WP2+1BNBJL" rel="nofollow">
                  <img class="a8-banner" border="0" width="300" height="250" alt="" src="https://www25.a8.net/svt/bgt?aid=250327576229&wid=001&eno=01&mid=s00000008903008003000&mc=1">
                </a>
                <img border="0" width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=451DZS+3SCAB6+1WP2+1BNBJL" alt="">
              </div>
            `,
          codeMobile: `
              <!-- A8サイドバー広告コード（モバイル用） -->
              <div class="a8-ad-container sidebar-ad-container-mobile">
                <a href="https://px.a8.net/svt/ejp?a8mat=451DZS+3SCAB6+1WP2+1BO6EP" rel="nofollow">
                  <img class="a8-banner" border="0" width="468" height="60" alt="" src="https://www27.a8.net/svt/bgt?aid=250327576229&wid=001&eno=01&mid=s00000008903008007000&mc=1">
                </a>
                <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=451DZS+3SCAB6+1WP2+1BO6EP" alt="">
              </div>
            `,
        },
        google: {
          codeDesktop: `
              <!-- Google広告コード（PC用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block"
                   data-ad-client="ca-pub-xxxxxxx"
                   data-ad-slot="1234567890"
                   data-ad-format="auto"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
          codeMobile: `
              <!-- Google広告コード（モバイル用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block; text-align:center;"
                   data-ad-client="ca-pub-xxxxxxx"
                   data-ad-slot="0987654321"
                   data-ad-format="fluid"
                   data-ad-layout-key="-fg+5n+6t-e7+r"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
        },
      },
      showOnPages: [
        "/ranking",
        "/shows/popular",
        "/shows/*",
        "/ranking/create",
      ],
    },
    {
      activeProvider: "a8",
      providers: {
        a8: {
          codeDesktop: `
              <div class="a8-ad-container sidebar-ad-container">
                <a href="https://px.a8.net/svt/ejp?a8mat=451DZS+3R5F3M+5NWU+5YZ75" rel="nofollow">
                <img border="0" class="a8-banner" border="0" width="300" height="250" alt="" src="https://www22.a8.net/svt/bgt?aid=250327576227&wid=001&eno=01&mid=s00000026427001003000&mc=1"></a>
                <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=451DZS+3R5F3M+5NWU+5YZ75" alt="">
              </div>
        `,
          codeMobile: `
              <div class="a8-ad-container sidebar-ad-container-mobile">
                <a href="https://px.a8.net/svt/ejp?a8mat=451DZS+3R5F3M+5NWU+614CX" rel="nofollow">
                <img border="0" class="a8-banner"  border="0" width="468" height="60" alt="" src="https://www27.a8.net/svt/bgt?aid=250327576227&wid=001&eno=01&mid=s00000026427001013000&mc=1"></a>
                <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=451DZS+3R5F3M+5NWU+614CX" alt="">
              </div>
            `,
        },
        google: {
          codeDesktop: `
              <!-- 別のGoogle広告コード（PC用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block"
                   data-ad-client="ca-pub-YYYYYYY"
                   data-ad-slot="2222222222"
                   data-ad-format="auto"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
          codeMobile: `
              <!-- 別のGoogle広告コード（モバイル用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block; text-align:center;"
                   data-ad-client="ca-pub-YYYYYYY"
                   data-ad-slot="3333333333"
                   data-ad-format="fluid"
                   data-ad-layout-key="-fg+5n+6t-e7+r"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
        },
      },
      showOnPages: ["/ranking"],
    },
  ],
  // header や ranking なども同様に必要なら配列に変更できます
  header: [
    {
      activeProvider: "a8",
      providers: {
        a8: {
          codeDesktop: `
              <!-- A8ヘッダー広告コード（PC用） -->
              <div class="a8-ad-container header-ad-container">
                <a href="https://px.a8.net/svt/ejp?a8mat=451GBN+FEAKZ6+4EKC+631SX" rel="nofollow">
                <img class="a8-banner" border="0" width="600" height="100" alt="" src="https://www20.a8.net/svt/bgt?aid=250330595931&wid=001&eno=01&mid=s00000020550001022000&mc=1"></a>
                <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=451GBN+FEAKZ6+4EKC+631SX" alt="">
              </div>
            `,
          codeMobile: `
              <!-- A8ヘッダー広告コード（モバイル用） -->
              <div class="a8-ad-container header-ad-container-mobile">
                <a href="https://px.a8.net/svt/ejp?a8mat=451GBN+FEAKZ6+4EKC+631SX" rel="nofollow">
                <img class="a8-banner" border="0" width="600" height="100" alt="" src="https://www20.a8.net/svt/bgt?aid=250330595931&wid=001&eno=01&mid=s00000020550001022000&mc=1"></a>
                <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=451GBN+FEAKZ6+4EKC+631SX" alt="">
              </div>
            `,
        },
        google: {
          codeDesktop: `
              <!-- Google広告コード（PC用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block"
                   data-ad-client="ca-pub-xxxxxxx"
                   data-ad-slot="1234567890"
                   data-ad-format="auto"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
          codeMobile: `
              <!-- Google広告コード（モバイル用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block; text-align:center;"
                   data-ad-client="ca-pub-xxxxxxx"
                   data-ad-slot="0987654321"
                   data-ad-format="fluid"
                   data-ad-layout-key="-fg+5n+6t-e7+r"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
        },
      },
      showOnPages: [
        "/ranking",
        "/shows/popular",
        "/shows/*",
        "/ranking/create",
      ],
    },
  ],
  ranking: [
    {
      activeProvider: "a8",
      providers: {
        a8: {
          codeDesktop: `
              <!-- A8ランキング広告（PC用） -->
              <div class="a8-ad-container ranking-ad-container">
                <a href="https://px.a8.net/svt/ejp?a8mat=451DZS+rankingPC" rel="nofollow">
                  <img class="a8-banner" border="0" width="468" height="60" alt="" src="https://www.a8.net/rankingPC.png">
                </a>
                <img border="0" width="1" height="1" src="https://www.a8.net/0.gif?a8mat=451DZS+rankingPC" alt="">
              </div>
            `,
          codeMobile: `
              <!-- A8ランキング広告（モバイル用） -->
              <div class="a8-ad-container ranking-ad-container-mobile">
                <a href="https://px.a8.net/svt/ejp?a8mat=451DZS+3SCAB6+1WP2+1BO6EP" rel="nofollow">
                  <img class="a8-banner" border="0" width="468" height="60" alt="" src="https://www27.a8.net/svt/bgt?aid=250327576229&wid=001&eno=01&mid=s00000008903008007000&mc=1">
                </a>
                <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=451DZS+3SCAB6+1WP2+1BO6EP" alt="">
              </div>
            `,
        },
        google: {
          codeDesktop: `
              <!-- Googleランキング広告（PC用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block"
                   data-ad-client="ca-pub-xxxxxxx"
                   data-ad-slot="rankingPC"
                   data-ad-format="auto"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
          codeMobile: `
              <!-- Googleランキング広告（モバイル用） -->
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
              <ins class="adsbygoogle"
                   style="display:block; text-align:center;"
                   data-ad-client="ca-pub-xxxxxxx"
                   data-ad-slot="rankingMobile"
                   data-ad-format="fluid"
                   data-ad-layout-key="-fg+5n+6t-e7+r"></ins>
              <script>
                   (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            `,
        },
      },
      showOnPages: ["/ranking"],
    },
  ],
};

export default adsConfig;
