// src/services/adminUserService.ts
export interface AdminUser {
  id: number;
  user_type: string;
  username: string;
  anonymous_uuid: string;
  google_id: string;
  twitter_id: string;
  spotify_id: string;
  ip_address: string;
  is_staff: boolean;
  created_at: string;
  updated_at: string;
}

export const fetchAdminUsers = async (
  page: number = 1,
  pageSize: number = 10,
  search?: string,
  ordering?: string
): Promise<AdminUser[]> => {
  const params = new URLSearchParams();
  params.append("page", String(page));
  params.append("page_size", String(pageSize));
  if (search) params.append("search", search);
  if (ordering) params.append("ordering", ordering);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const token = localStorage.getItem("auth_token");
  const response = await fetch(
    `${API_BASE_URL}/api/admin/users/?${params.toString()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch users");
  }
  const json = await response.json();
  return json.results; // resultsプロパティを返す
};

export const deleteAdminUser = async (userId: number): Promise<void> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const token = localStorage.getItem("auth_token");
  const response = await fetch(
    `${API_BASE_URL}/api/admin/users/${userId}/delete/`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to delete user");
  }
};
