// src/services/adminEpisodeService.ts
export interface Episode {
  id: number;
  spotify_episode_id: string;
  name: string;
  description: string;
  thumbnail_url: string;
  duration_ms: number;
  release_date: string;
  external_url: string;
  created_at: string;
  updated_at: string;
}

// ページネーションのレスポンス型を使わず、単純に Episode[] を返す
export const fetchAdminEpisodes = async (
  showId: number
): Promise<Episode[]> => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
  const token = localStorage.getItem("auth_token");
  const response = await fetch(
    `${API_BASE_URL}/api/admin/shows/${showId}/episodes/`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch episodes");
  }
  return response.json(); // ここは単なる JSON 配列を返す
};
