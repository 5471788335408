// src/components/Modal.tsx
import React from "react";
import styled from "styled-components";

const MOBILE_BREAKPOINT = "480px";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null; // 表示しない

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // モーダル背景部をクリックしたら閉じる
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <ModalCard>
        <CloseButton onClick={onClose}>×</CloseButton>
        {children}
      </ModalCard>
    </Overlay>
  );
};

export default Modal;

// styled-components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); // 白がかった背景
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalCard = styled.div`
  position: relative;
  background-color: #fff;
  width: 500px; /* モーダル幅(適宜調整) */
  max-width: 80%;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 2.8rem;
  }
`;

// 使用方法
// import React, { useState } from 'react';
// import Modal from './components/Modal';
// import Button from './components/Button';

// function App() {
//   const [openModal, setOpenModal] = useState(false);

//   return (
//     <div>
//       <Button onClick={() => setOpenModal(true)}>Open Modal</Button>

//       <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
//         <h2>モーダルタイトル</h2>
//         <p>ここに説明文などを配置します。</p>
//         <Button onClick={() => setOpenModal(false)}>OK</Button>
//       </Modal>
//     </div>
//   );
// }

// export default App;
