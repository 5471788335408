import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  Container,
  RankingCard,
  RankingTitle,
  RankingDiscription,
  Creator,
  EpisodeList,
  EpisodeItem,
  EpisodeThumbnail,
  EpisodeTitle,
  LikeCount,
  RankingTags,
  Tag,
  EpisodeInfo,
  EpisodeShowName,
  ModalImage,
  ModalContent,
  ModalDescription,
} from "./RankingList.styles";
import LoadingIndicator from "../../components/LoadingIndicator";
import { FaSpotify } from "react-icons/fa";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import Modal from "../../components/Modal";
import {
  fetchRankings,
  likeRanking,
  Ranking,
} from "../../services/rankingService";
import useDeviceType from "../../hooks/useDeviceType";
import AdSlot from "../../components/AdSlot";

// react-share のインポート
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";

const HeartIcon = FaHeart as React.FC<{ style?: React.CSSProperties }>;
const RegHeartIcon = FaRegHeart as React.FC<{ style?: React.CSSProperties }>;
const SpotifyIcon = FaSpotify as unknown as React.FC<{ size?: number }>;

const MOBILE_BREAKPOINT = "480px";

// Spotify ボタンのスタイル
const StyledSpotifyButton = styled.a`
  border: 1px solid #ccc;
  width: 260px;
  height: 34px;
  margin-left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1db954;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #17a74a;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 90%;
    height: 30px;
    margin-top: 0.5rem;
  }
`;

const IconContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-left: 0;
  }
`;

const TextContainer = styled.span`
  flex: 1;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  padding-left: 0.5rem;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding-left: 0.2rem;
  }
`;

export const SpotifyButton: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, ...props }) => {
  return (
    <StyledSpotifyButton {...props}>
      <IconContainer>
        <SpotifyIcon size={20} />
      </IconContainer>
      <TextContainer>{children}</TextContainer>
    </StyledSpotifyButton>
  );
};

// ソートボタン用 styled components
const SortButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

const SortButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.5rem;
  font-weight: 600;
`;

// シェアボタン用の styled components
const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  > * {
    margin-right: 0.5rem;
  }
`;

/**
 * 各シェアアイコンを丸い背景でラップするコンポーネント
 */
const ShareIconWrapperFacebook = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(9, 101, 254);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  cursor: pointer;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 32px;
    height: 32px;
  }
`;

const ShareIconWrapperTwitter = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  cursor: pointer;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 32px;
    height: 32px;
  }
`;

const ShareIconWrapperLinkedin = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(0, 119, 181);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  cursor: pointer;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 32px;
    height: 32px;
  }
`;

const RankingList: React.FC = () => {
  // 初回ロード時のローディング
  const [initialLoading, setInitialLoading] = useState(true);
  // 検索時のローディング（一覧のみ）
  const [searchLoading, setSearchLoading] = useState(false);

  const [rankings, setRankings] = useState<Ranking[]>([]);
  // 初回取得データのキャッシュ
  const [initialRankings, setInitialRankings] = useState<Ranking[]>([]);
  const [error, setError] = useState<string | null>(null);

  // 検索キーワード
  const [searchQuery, setSearchQuery] = useState("");

  // ソートオプション（"date": 日にち順、"like": いいね数順）
  const [sortOption, setSortOption] = useState<"date" | "like">("date");
  // 日にち順のソート方向（"new": 新しい順、"old": 古い順）
  const [dateSortOrder, setDateSortOrder] = useState<"new" | "old">("new");

  // モーダル制御用
  const [modalOpen, setModalOpen] = useState(false);
  // クリックされたエピソードの情報を格納
  const [selectedEpisode, setSelectedEpisode] = useState<any>(null);

  // 検索リクエストの最新管理用
  const latestSearchQueryId = useRef(0);

  // 広告用デバイスタイプによる条件分岐
  const deviceType = useDeviceType();
  const adInsertIndex = 3;

  // コンポーネント初回マウント時: ランキング取得
  useEffect(() => {
    fetchRankings()
      .then((data) => {
        setRankings(data);
        setInitialRankings(data);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, []);

  // searchQuery が変化したら、デバウンス処理を行い再取得
  useEffect(() => {
    if (!initialLoading) {
      // 検索キーワードが空の場合、初回取得データを再利用
      if (searchQuery.trim() === "") {
        setRankings(initialRankings);
        return;
      }
      setSearchLoading(true);
      latestSearchQueryId.current++;
      const currentId = latestSearchQueryId.current;
      const handler = setTimeout(() => {
        fetchRankings(searchQuery)
          .then((data) => {
            if (currentId === latestSearchQueryId.current) {
              setRankings(data);
            }
          })
          .catch((err) => {
            setError(err.message);
          })
          .finally(() => {
            if (currentId === latestSearchQueryId.current) {
              setSearchLoading(false);
            }
          });
      }, 300);
      return () => clearTimeout(handler);
    }
  }, [searchQuery, initialLoading, initialRankings]);

  // いいねボタンクリック
  const handleLikeClick = async (ranking: Ranking, index: number) => {
    try {
      const res = await likeRanking(ranking.id);
      const newRankings = [...rankings];
      newRankings[index] = {
        ...ranking,
        like_count: res.like_count,
        has_liked: res.has_liked,
      };
      setRankings(newRankings);
    } catch (err: any) {
      alert(err.message || "Failed to like ranking");
    }
  };

  // サムネイルクリック => モーダルオープン
  const handleThumbnailClick = (episode: any) => {
    setSelectedEpisode(episode);
    setModalOpen(true);
  };

  // ランキングのソート
  const sortedRankings = React.useMemo(() => {
    if (sortOption === "date") {
      return [...rankings].sort((a, b) => {
        const timeA = new Date(a.created_at).getTime();
        const timeB = new Date(b.created_at).getTime();
        return dateSortOrder === "new" ? timeB - timeA : timeA - timeB;
      });
    } else if (sortOption === "like") {
      return [...rankings].sort((a, b) => b.like_count - a.like_count);
    }
    return rankings;
  }, [rankings, sortOption, dateSortOrder]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (initialLoading) {
    return <LoadingIndicator isFullPage={true} color="primary" size={80} />;
  }

  return (
    <Container>
      {/* 検索インプット */}
      <div
        style={{
          marginBottom: "1rem",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <input
          type="text"
          style={{
            width: "100%",
            height: "2rem",
            border: "1px solid #1DB954",
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search rankings..."
        />
      </div>

      {searchLoading ? (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <LoadingIndicator isFullPage={false} color="primary" size={40} />
        </div>
      ) : (
        <>
          <SortButtonsContainer>
            <SortButton
              onClick={() => {
                if (sortOption !== "date") {
                  setSortOption("date");
                  setDateSortOrder("new");
                } else {
                  setDateSortOrder((prev) => (prev === "new" ? "old" : "new"));
                }
              }}
            >
              {sortOption === "date"
                ? dateSortOrder === "new"
                  ? "古い順に並び替え"
                  : "新しい順に並び替え"
                : "日にち順に並び替え"}
            </SortButton>
            <SortButton onClick={() => setSortOption("like")}>
              いいね数順に並び替え
            </SortButton>
          </SortButtonsContainer>

          {sortedRankings.map((ranking, idx) => {
            const shareUrl = `${window.location.origin}/ranking/${ranking.id}`;
            return (
              <>
                <RankingCard key={ranking.id}>
                  <RankingTitle>{ranking.title}</RankingTitle>
                  <RankingDiscription>{ranking.description}</RankingDiscription>
                  <Creator>作成者: {ranking.creator}</Creator>
                  <small>作成日: {ranking.created_at}</small>

                  <EpisodeList>
                    {ranking.ranking_episodes.map((ep) => (
                      <EpisodeItem key={ep.id}>
                        <EpisodeThumbnail
                          src={ep.thumbnail_url}
                          alt={ep.name}
                          onClick={() => handleThumbnailClick(ep)}
                        />
                        <EpisodeInfo>
                          {ep.show_name && (
                            <EpisodeShowName>{ep.show_name}</EpisodeShowName>
                          )}
                          <EpisodeTitle>{ep.name}</EpisodeTitle>
                          <SpotifyButton
                            href={ep.external_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Listen on Spotify
                          </SpotifyButton>
                        </EpisodeInfo>
                      </EpisodeItem>
                    ))}
                  </EpisodeList>

                  <LikeCount>{ranking.like_count} likes</LikeCount>

                  {!ranking.is_own && (
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleLikeClick(ranking, idx)}
                    >
                      {ranking.has_liked ? (
                        <HeartIcon
                          style={{ color: "red", fontSize: "1.2rem" }}
                        />
                      ) : (
                        <RegHeartIcon
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      )}
                    </button>
                  )}

                  <RankingTags>
                    {ranking.ranking_tags.map((tag, i) => (
                      <Tag key={i}>{tag}</Tag>
                    ))}
                  </RankingTags>

                  <ShareContainer>
                    <FacebookShareButton url={shareUrl} hashtag="#Ranking">
                      <ShareIconWrapperFacebook>
                        <FacebookIcon size={32} round={true} />
                      </ShareIconWrapperFacebook>
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} title={ranking.title}>
                      <ShareIconWrapperTwitter>
                        <FontAwesomeIcon icon={faXTwitter} size="lg" />
                      </ShareIconWrapperTwitter>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={shareUrl}
                      title={ranking.title}
                      summary={ranking.description}
                    >
                      <ShareIconWrapperLinkedin>
                        <LinkedinIcon size={32} round={true} />
                      </ShareIconWrapperLinkedin>
                    </LinkedinShareButton>
                  </ShareContainer>
                  {/* モバイルの場合、指定の位置に広告を挿入 */}
                </RankingCard>
                {deviceType === "mobile" && idx === adInsertIndex && (
                  <AdSlot slot="ranking" />
                )}
              </>
            );
          })}
        </>
      )}

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        {selectedEpisode && (
          <ModalContent>
            <ModalImage
              src={selectedEpisode.thumbnail_url}
              alt={selectedEpisode.name}
            />
            {selectedEpisode.show_name && (
              <h3 style={{ margin: "0 0 0.5rem" }}>
                {selectedEpisode.show_name}
              </h3>
            )}
            <h4 style={{ margin: "0 0 1rem", color: "#333" }}>
              {selectedEpisode.name}
            </h4>
            {selectedEpisode.description && (
              <ModalDescription>{selectedEpisode.description}</ModalDescription>
            )}
            {selectedEpisode.release_date && (
              <p style={{ color: "#666" }}>
                Release Date: {selectedEpisode.release_date}
              </p>
            )}
          </ModalContent>
        )}
      </Modal>
    </Container>
  );
};

export default RankingList;
