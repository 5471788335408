// src/routes/PublicRoute.tsx
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import LoadingIndicator from "../components/LoadingIndicator";

interface PublicRouteProps {
  children: React.ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return <LoadingIndicator isFullPage={true} color="primary" size={80} />;
  }
  if (user) {
    return <Navigate to="/ranking" replace />;
  }
  return <>{children}</>;
};

export default PublicRoute;
