// src/pages/SocialLoginCallback/SocialLoginCallback.tsx

import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";

/**
 * ソーシャルログイン成功後、
 *   /social-login?access=xxx&refresh=yyy
 * へDjango側がリダイレクトしてくる想定。
 * 両トークンを localStorage に保存し、UserContext も更新。
 */
const SocialLoginCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setToken } = useContext(UserContext);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const access = query.get("access");
    const refresh = query.get("refresh");

    if (access && refresh) {
      localStorage.setItem("auth_token", access);
      localStorage.setItem("refresh_token", refresh);
      setToken(access);
      // 必要なら UserContext.setUser(...) or /me/ を再度呼び出し可能
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate, setToken]);

  return <div>ソーシャルログイン処理中です...</div>;
};

export default SocialLoginCallback;
