import styled from "styled-components";

const MOBILE_BREAKPOINT = "480px";
const MOBILE_BREAKPOINT2 = "768px";

export const ContentTitle = styled.h2`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
`;

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0.5rem;
  }
`;

export const Section = styled.div`
  margin-bottom: 1.5rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 0rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0.4rem;
    font-size: 0.9rem;
    width: 90%;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0.4rem;
    font-size: 0.9rem;
    width: 90%;
  }
`;

export const SearchResultsContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 1rem;
  background-color: #fff;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 0.8rem;
  }
`;

export const ShowItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ShowThumbnail = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 8px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-right: 0;
    margin-bottom: 0.5rem;
    width: 60px;
    height: 60px;
  }
`;

export const ShowInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
    text-align: center;
  }
`;

export const ShowTitle = styled.div`
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 0.9rem;
    text-align: left;
  }
`;

export const ShowDescription = styled.div`
  font-size: 0.9rem;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 0.8rem;
  }
`;

export const EpisodeList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 0rem;
    margin-bottom: 2.5rem;
  }
`;

export const EpisodeItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const TagItem = styled.div`
  background-color: #f2f2f2;
  color: #333;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 0.9rem;
  }
`;

export const TagRemoveButton = styled.button`
  background: transparent;
  border: none;
  color: #e74c3c;
  margin-left: 0.25rem;
  cursor: pointer;
`;

export const ModalTitle = styled.h2`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 1.25rem;
  }
`;

export const CloseButton = styled.button`
  position: sticky;
  top: 125px; /* ヘッダーの高さに合わせて調整 */
  z-index: 100; /* ヘッダーより大きな値に設定 */
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  align-self: stretch;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #c0392b;
  }

  @media (max-width: ${MOBILE_BREAKPOINT2}) {
    top: 85px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
`;
