// src/components/SocialButton.tsx

import React from "react";
import Button from "./Button";
import styled from "styled-components";
import { IconType } from "react-icons";
import { FaSpotify, FaUser } from "react-icons/fa";
import { SiLine } from "react-icons/si";

// GoogleLogo コンポーネントを同一ファイル内に定義
interface GoogleLogoProps extends React.SVGAttributes<SVGSVGElement> {
  width?: number;
  height?: number;
}

const GoogleLogo: React.FC<GoogleLogoProps> = ({
  width = 20,
  height = 20,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#EA4335"
      d="M24 9.5c3.8 0 7.2 1.5 9.7 3.9l6.6-6.6C35.2 3.5 29.9 1 24 1
         14.8 1 6.9 5.8 3.2 12.5l7.6 5.9C12.3 12.8 17.7 9.5 24 9.5z"
    />
    <path
      fill="#FBBC05"
      d="M10.8 28.2c-1.1-3.2-1.1-6.7 0-9.9L3.2 12.5
         C-0.5 19.2-0.5 26.9 3.2 33.6l7.6-5.9z"
    />
    <path
      fill="#34A853"
      d="M24 47c6.5 0 12-2.1 16-5.7l-7.6-5.9
         c-2.1 1.4-4.8 2.3-8.4 2.3-6.3 0-11.7-4.2-13.6-9.9l-7.6 5.9
         C6.9 42.2 14.8 47 24 47z"
    />
    <path
      fill="#4285F4"
      d="M46.1 24c0-1.6-.1-3.1-.4-4.5H24v8.5h12.5
         c-.5 2.8-2 5.2-4.3 6.9l7.1 5.5C43.3 35.2 46.1 30 46.1 24z"
    />
  </svg>
);

// SocialButton の型定義
export interface SocialButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  provider: "google" | "line" | "spotify" | "anonymous";
  as?: React.ElementType;
  href?: string;
  target?: string;
  rel?: string;
}

// プロバイダーごとの配色
const ProviderStyles: Record<
  SocialButtonProps["provider"],
  { bgColor: string; textColor: string }
> = {
  google: { bgColor: "#fff", textColor: "#000" },
  line: { bgColor: "#06C755", textColor: "#ffffff" },
  spotify: { bgColor: "#1DB954", textColor: "#ffffff" },
  anonymous: { bgColor: "#6c757d", textColor: "#ffffff" },
};

// ホバー時の色
const ProviderHoverStyles: Record<SocialButtonProps["provider"], string> = {
  google: "#e0e0e0",
  line: "#05b14d",
  spotify: "#17a74a",
  anonymous: "#5a6268",
};

// "google" 以外の "line" | "spotify" | "anonymous" のアイコン
const ProviderIcons: Record<"line" | "spotify" | "anonymous", IconType> = {
  line: SiLine,
  spotify: FaSpotify,
  anonymous: FaUser,
};

interface StyledSocialButtonProps {
  bgColor?: string;
  textColor?: string;
  hoverBg?: string;
}
const StyledSocialButton = styled(Button)<StyledSocialButtonProps>`
  border: 1px solid
    ${({ theme }) => (theme.colors?.border ? theme.colors.border : "#ccc")};
  width: 300px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};

  &:hover {
    background-color: ${({ hoverBg }) => hoverBg};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 33px;
`;
const IconContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TextContainer = styled.span`
  flex: 1;
  text-align: left;
  padding-left: 0.5rem;
`;

const SocialButton: React.FC<SocialButtonProps> = ({
  provider,
  children,
  className,
  ...rest
}) => {
  const { bgColor, textColor } = ProviderStyles[provider];
  const hoverBg = ProviderHoverStyles[provider];

  return (
    <StyledSocialButton
      className={className}
      bgColor={bgColor}
      textColor={textColor}
      hoverBg={hoverBg}
      {...rest}
    >
      <ContentWrapper>
        <IconContainer>
          {provider === "google" ? (
            // Google の場合は GoogleLogo を使用
            <GoogleLogo width={20} height={20} style={{ marginRight: 0 }} />
          ) : (
            // line, spotify, anonymous の場合
            (() => {
              const IconComp =
                ProviderIcons[provider as "line" | "spotify" | "anonymous"];
              if (!IconComp) {
                return null;
              }
              const IconComponent = IconComp as React.ElementType;
              return <IconComponent size={20} />;
            })()
          )}
        </IconContainer>
        <TextContainer>{children}</TextContainer>
      </ContentWrapper>
    </StyledSocialButton>
  );
};

export { StyledSocialButton };
export default SocialButton;
