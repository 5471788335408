// src/utils/getOrCreateAnonymousUUID.ts
import { v4 as uuidv4 } from "uuid";

export default function getOrCreateAnonymousUUID(): string {
  let anonymousUuid = localStorage.getItem("anonymous_uuid");
  if (!anonymousUuid) {
    anonymousUuid = uuidv4();
    localStorage.setItem("anonymous_uuid", anonymousUuid);
  }
  return anonymousUuid;
}
