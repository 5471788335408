// src/layouts/FrontLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "styled-components";

const ContentWrapper = styled.div`
  padding-top: 80px; /* ヘッダーの高さ分の余白を確保 */
  min-height: calc(100vh - 160px); /* ヘッダーとフッターの高さ分を引く */
`;

const FrontLayout: React.FC = () => {
  return (
    <>
      <Header />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default FrontLayout;
