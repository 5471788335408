import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Sidebar from "../Sidebar/Sidebar";

const MOBILE_BREAKPOINT = "1024px";

// 明るく柔らかいグラデーションがゆっくり動くアニメーション
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// 背景は明るい緑を中心に、薄めの色合いを使用
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #b5e48c, #99d98c, #52b788, #2a9d8f);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 20s ease infinite;
  z-index: -2;
`;

// 白いオーバーレイ。opacity はスクロールに応じて変化
const WhiteOverlay = styled.div<{ opacity: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: ${(props) => props.opacity};
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  z-index: -1;
`;

// コンテンツの外枠：ガラスモーフィズム風の明るい半透明カード
const LayoutContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    margin: 0;
    padding: 0;
    max-width: none;
  }
`;

// メインコンテンツエリア
const MainContent = styled.div`
  flex: 1;
  padding: 1rem;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 0rem;
    width: 100%;
  }
`;

// サイドバーを包むラッパー：こちらも明るいトーンのカードに
const SidebarWrapper = styled.div`
  width: 300px;
  padding: 1rem;
  border-radius: 12px;
  margin-left: 1rem;
  height: 100vh;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: auto;
    top: auto;
  }
`;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  // スクロール進捗（0～1）を保持するstate
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const docHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const progress = docHeight ? scrollTop / docHeight : 0;
      setScrollProgress(Math.min(Math.max(progress, 0), 1));
    };

    window.addEventListener("scroll", handleScroll);
    // 初期状態の設定
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Background />
      <WhiteOverlay opacity={1 - scrollProgress} />
      <LayoutContainer>
        <MainContent>{children}</MainContent>
        <SidebarWrapper>
          <Sidebar />
        </SidebarWrapper>
      </LayoutContainer>
    </>
  );
};

export default Layout;
