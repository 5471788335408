// src/components/Footer.tsx
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterContainer = styled.footer`
  background-color: #333;
  color: ${(props) => props.theme.colors.white};
  padding: 2rem;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 8rem;
  @media (max-width: 768px) {
    margin-top: 4rem;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterLinks = styled.ul`
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
`;

const FooterLinkItem = styled.li`
  a {
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
    margin-bottom: 1rem;
    display: inline-block;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const FooterCopy = styled.div`
  margin-top: 5rem;
  font-size: 0.9rem;
  opacity: 0.7;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinks>
          <FooterLinkItem>
            <Link to="/ranking">ランキング一覧</Link>
          </FooterLinkItem>
          <FooterLinkItem>
            <Link to="/ranking/create">ランキング作成</Link>
          </FooterLinkItem>
          <FooterLinkItem>
            <Link to="/shows/popular">人気の番組</Link>
          </FooterLinkItem>
          <FooterLinkItem>
            <Link to="/terms-of-service">利用規約</Link>
          </FooterLinkItem>
          <FooterLinkItem>
            <Link to="/privacy-policy">プライバシーポリシー</Link>
          </FooterLinkItem>
          <FooterLinkItem>
            <Link to="/contact">お問い合わせ</Link>
          </FooterLinkItem>
        </FooterLinks>
        <FooterCopy>
          © {new Date().getFullYear()} Emine. All rights reserved.
        </FooterCopy>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
