import React, { useEffect, useState } from "react";
import {
  Container,
  Section,
  SectionTitle,
  Header,
  ToggleContainer,
} from "./AdminDashboard.styles";
import { fetchAdminShows, Show } from "../../services/adminShowService";
import { fetchAdminUsers, AdminUser } from "../../services/adminUserService";
import {
  fetchAdminRankings,
  Ranking,
} from "../../services/adminRankingService";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import LoadingIndicator from "../../components/LoadingIndicator";
import ToggleButton from "../../components/ToggleButton";

const AdminDashboard: React.FC = () => {
  const [shows, setShows] = useState<Show[]>([]);
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [rankings, setRankings] = useState<Ranking[]>([]);
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([
      fetchAdminShows(1, 10),
      fetchAdminUsers(1, 10),
      fetchAdminRankings(1, 10),
    ])
      .then(([showData, userData, rankingData]) => {
        setShows(showData);
        setUsers(userData); // ここで results を抽出
        setRankings(rankingData);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return <LoadingIndicator isFullPage={true} color="primary" size={80} />;

  const handleToggle = () => {
    setIsDarkMode((prev) => !prev);
  };

  return (
    <div
      style={{
        backgroundColor: isDarkMode ? "rgb(38 37 37)" : "#fff",
        minHeight: "100vh",
        color: isDarkMode ? "#fff" : "#000",
        transition: "background-color 0.3s, color 0.3s",
      }}
    >
      <Header>
        <h1>adminDashboard</h1>
        <ToggleContainer>
          <ToggleButton isOn={isDarkMode} onToggle={handleToggle} />
        </ToggleContainer>
      </Header>
      <Container>
        <Section>
          <SectionTitle>新規取得した番組</SectionTitle>
          <ul>
            {shows.map((s) => (
              <li
                key={s.id}
                onClick={() => navigate(`/admin/shows/${s.id}`)}
                style={{ cursor: "pointer" }}
              >
                {s.name}
              </li>
            ))}
          </ul>
          <Button
            textColor={isDarkMode ? "#000" : "#000"}
            rounded={true}
            onClick={() => navigate("/admin/shows")}
          >
            全ての番組を見る
          </Button>
        </Section>

        <Section>
          <SectionTitle>新規登録ユーザー</SectionTitle>
          <ul>
            {users.map((u) => (
              <li key={u.id}>
                {u.anonymous_uuid || `${u.username}`}, user_id: {u.id}, 登録日:{" "}
                {new Date(u.created_at).toLocaleDateString()}, user_type:{" "}
                {u.user_type}
              </li>
            ))}
          </ul>
          <Button
            textColor={isDarkMode ? "#000" : "#000"}
            rounded={true}
            onClick={() => navigate("/admin/users")}
          >
            全てのユーザーを見る
          </Button>
        </Section>

        <Section>
          <SectionTitle>新しく作成されたランキング</SectionTitle>
          <ul>
            {rankings.map((r) => (
              <li key={r.id}>
                {r.title} (いいね: {r.like_count_cache})
              </li>
            ))}
          </ul>
          <Button
            textColor={isDarkMode ? "#000" : "#000"}
            rounded={true}
            onClick={() => navigate("/admin/rankings")}
          >
            全てのランキングを見る
          </Button>
        </Section>
      </Container>
    </div>
  );
};

export default AdminDashboard;
