// src/pages/RankingList/RankingListPage.tsx
import React from "react";
import Layout from "../Layout/Layout";
import RankingList from "./RankingList";

const RankingListPage: React.FC = () => {
  return (
    <Layout>
      <RankingList />
    </Layout>
  );
};

export default RankingListPage;
