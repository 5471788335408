// src/pages/UserSettings/UserSettings.tsx
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserSettingsContainer,
  Title,
  FormGroup,
  Label,
  Input,
  Actions,
} from "./UserSettings.styles";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import LoadingIndicator from "../../components/LoadingIndicator";
import NotificationBar from "../../components/NotificationBar";
import { authService } from "../../services/authService";
import { UserContext } from "../../contexts/userContext";

interface NotificationData {
  message: string;
  bgColor: string;
  textColor: string;
}

const UserSettings: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const [username, setUsername] = useState(user?.username || "");
  const [notif, setNotif] = useState<NotificationData>({
    message: "",
    bgColor: "",
    textColor: "",
  });
  const [showNotif, setShowNotif] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const handleCloseNotif = () => {
    setShowNotif(false);
  };

  // ユーザー名更新処理
  const handleUpdateUsername = async () => {
    setShowNotif(false);
    setIsUpdating(true);
    try {
      const updatedUser = await authService.updateCurrentUser({ username });
      setUser(updatedUser);
      setNotif({
        message: "ユーザー名を更新しました。",
        bgColor: "success",
        textColor: "white",
      });
      setShowNotif(true);
    } catch (err) {
      console.error(err);
      setNotif({
        message: "ユーザー名の更新に失敗しました。",
        bgColor: "danger",
        textColor: "white",
      });
      setShowNotif(true);
    } finally {
      setIsUpdating(false);
    }
  };

  // ログアウト処理（ユーザー状態をリセット）
  const handleLogout = () => {
    authService.logout();
    setUser(null); // ユーザー状態をクリア
    navigate("/login");
  };

  // アカウント削除処理（削除後にユーザー状態をリセット）
  const handleDeleteAccount = async () => {
    setShowNotif(false);
    setIsDeleting(true);
    try {
      await authService.deleteCurrentUser();
      setNotif({
        message: "アカウントを削除しました。",
        bgColor: "success",
        textColor: "white",
      });
      setShowNotif(true);
      authService.logout();
      setUser(null); // ユーザー状態をクリア
      navigate("/login");
    } catch (err) {
      console.error(err);
      setNotif({
        message: "アカウントの削除に失敗しました。",
        bgColor: "danger",
        textColor: "white",
      });
      setShowNotif(true);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <UserSettingsContainer>
      <Title>ユーザー設定</Title>

      {showNotif && (
        <NotificationBar
          message={notif.message}
          bgColor={notif.bgColor}
          textColor={notif.textColor}
          isOpen={showNotif}
          onClose={handleCloseNotif}
          duration={10000}
        />
      )}

      <FormGroup>
        <Label htmlFor="username">ユーザー名</Label>
        <Input
          id="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your username"
        />
      </FormGroup>

      {isUpdating ? (
        <LoadingIndicator size={40} />
      ) : (
        <Button onClick={handleUpdateUsername} bgColor="primary">
          ユーザー名更新
        </Button>
      )}

      <Actions>
        <Button onClick={handleLogout} bgColor="warning">
          ログアウト
        </Button>
        <Button onClick={() => setShowDeleteModal(true)} bgColor="#ff1800">
          アカウント削除
        </Button>
      </Actions>

      <Modal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        {isDeleting ? (
          <LoadingIndicator size={40} />
        ) : (
          <>
            <h2>アカウントを削除してもよろしいですか?</h2>
            <p>
              この処理は即時実行されます。アカウントを削除すると、すべてのデータが失われます。
            </p>
            <Actions>
              <Button onClick={() => setShowDeleteModal(false)} bgColor="gray">
                キャンセル
              </Button>
              <Button onClick={handleDeleteAccount} bgColor="danger">
                アカウント削除
              </Button>
            </Actions>
          </>
        )}
      </Modal>
    </UserSettingsContainer>
  );
};

export default UserSettings;
