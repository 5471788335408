// src/pages/UserSettings/UserSettings.styles.ts
import styled from "styled-components";

export const UserSettingsContainer = styled.div`
  max-width: 600px;
  margin: 2rem auto;
  margin-top: 4rem;
  padding: 3rem;
  padding-top: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: 0 2px 8px rgba(0, 0, 0.2, 0.4);

  @media (max-width: 480px) {
    margin: 1rem;
    padding: 0.5rem;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  color: #000;
  margin-bottom: 1.5rem;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const Input = styled.input`
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  outline: none;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 480px) {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 5rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 40%;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  text-align: center;
  margin-top: 0.5rem;
`;
