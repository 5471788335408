// src/pages/ShowEpisodes/ShowEpisodesPage.styles.ts
import styled from "styled-components";

const MOBILE_BREAKPOINT = "768px";

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
`;

export const SearchContainer = styled.form`
  display: flex;
  margin-bottom: 1rem;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
`;

export const ToggleSortButton = styled.button`
  display: inline-flex;
  align-items: center;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: 1rem;
  &:hover {
    background: #0056b3;
  }
`;

export const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const EpisodeImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
`;

export const EpisodeDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const EpisodeTitle = styled.h3`
  margin: 0;
  font-size: 1.3rem;
  color: #333;
`;

export const EpisodeDescription = styled.p`
  margin: 0;
  font-size: 0.95rem;
  color: #555;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

// 追加: リリース日表示用のスタイル
export const EpisodeReleaseDate = styled.span`
  margin-top: auto;
  text-align: left;
  font-size: 0.8rem;
  color: #888;
`;

export const LoadingText = styled.p`
  text-align: center;
  font-size: 1rem;
  color: #555;
`;

export const ErrorText = styled.p`
  text-align: center;
  font-size: 1rem;
  color: red;
`;

/* 以下、番組詳細情報表示用のスタイル */

export const ShowInfoContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  left: 50%;
  top: -16px;
  transform: translateX(-50%);
  overflow: hidden;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.6rem;

  &::before {
    content: "";
    position: absolute;
    top: -10%;
    left: -2.5vw;
    width: 100%;
    height: 150%;
    background-image: linear-gradient(135deg, #fff176 0%, transparent 20%),
      linear-gradient(45deg, #ff8a80 10%, transparent 30%),
      linear-gradient(90deg, #8c9eff 20%, transparent 40%),
      linear-gradient(135deg, #80d8ff 30%, transparent 50%),
      linear-gradient(45deg, #a7ffeb 40%, transparent 60%);
    background-blend-mode: multiply;
    opacity: 0.4;
    pointer-events: none;
    z-index: -1;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    height: auto;
    text-align: center;
    padding: 1rem;
  }
`;

export const ShowThumbnail = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 150px;
    height: 150px;
  }
`;

export const ShowTextContainer = styled.div`
  flex: 1;
`;

export const ShowName = styled.h2`
  margin: 0 0 0.5rem 0;
  font-size: 1.8rem;
  color: #333;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 1.2rem;
  }
`;

export const ShowDescriptionText = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #555;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    text-align: left;
    font-size: 0.8rem;
  }
`;
