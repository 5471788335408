import styled from "styled-components";

const MOBILE_BREAKPOINT = "768px";

export const SidebarContainer = styled.div`
  width: auto;
  padding: 1rem;
  background-color: #fff;
  border: 2px solid #dadada;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-top: 20px;
    margin-bottom: 3rem;
  }
`;

export const SectionTitle = styled.h3`
  margin: 0.5rem 0;
  font-size: 1.2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.3rem;
`;

export const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 3.5rem 0;
`;

export const Item = styled.li`
  margin-bottom: 1rem;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    &:hover {
      opacity: 0.85;
    }
  }
`;

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`;

export const Thumbnail = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  display: block;
  border-radius: 4px;
`;

export const MedalBadge = styled.div<{ rank: number }>`
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: ${(props) =>
    props.rank === 1
      ? "gold"
      : props.rank === 2
      ? "silver"
      : props.rank === 3
      ? "#cd7f32"
      : "transparent"};
  color: #fff;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8rem;
`;

export const ItemTitle = styled.div`
  margin-left: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
`;
