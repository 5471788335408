// src/services/apiClient.ts
/**
 * fetch をラップして、常に Bearer トークンを付与し、
 * もし 401 (Unauthorized) が返ってきたらリフレッシュトークンで再取得し、
 * 再リクエストする仕組みをまとめた関数
 */
export async function apiClient(
  url: string,
  options: RequestInit = {}
): Promise<Response> {
  // 1) 既存のアクセストークン/リフレッシュトークンを取得
  let accessToken = localStorage.getItem("auth_token");
  let refreshToken = localStorage.getItem("refresh_token");

  // 2) Authorization ヘッダを付与
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    // もともと options.headers にあった値と合体してもOK
    ...(options.headers as Record<string, string>),
  };
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  // fetch オプションを再構築
  let newOptions: RequestInit = {
    ...options,
    headers,
  };

  // 3) 通常リクエストを実行
  let response = await fetch(url, newOptions);

  // 4) もし 401 だったら → リフレッシュトークンがあればトライ
  if (response.status === 401 && refreshToken) {

    // リフレッシュAPIに問い合わせ (SimpleJWT の TokenRefreshView)
    // 例: /api/token/refresh/
    const refreshResponse = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/auth/token/refresh/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh: refreshToken }),
      }
    );

    if (refreshResponse.ok) {
      // リフレッシュ成功 → アクセストークンを更新し、再リクエスト
      const refreshData = await refreshResponse.json();
      // { access: "new_access_token", refresh: "new_refresh_token"? } (設定次第)

      const newAccess = refreshData.access;
      const newRefresh = refreshData.refresh; // もし返ってくるなら

      // localStorage のトークンを更新
      if (newAccess) {
        localStorage.setItem("auth_token", newAccess);
      }
      if (newRefresh) {
        localStorage.setItem("refresh_token", newRefresh);
      }

      // 再度 Authorization ヘッダを付け直して同じURLに再リクエスト
      newOptions = {
        ...newOptions,
        headers: {
          ...headers,
          Authorization: `Bearer ${newAccess}`,
        },
      };
      response = await fetch(url, newOptions);
    } else {
      // リフレッシュ失敗 → 完全にログアウト状態にする
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      // 必要に応じて window.location.href = "/login" など
    }
  }

  return response;
}
