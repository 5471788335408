import styled from "styled-components";

export const Container = styled.div`
  max-width: 400px;
  margin: 3rem auto;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

// SocialButton をラップして下にスペースを追加するラッパー
export const ButtonWrapper = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
`;
