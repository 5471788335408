// src/pages/PopularShows/PopularShows.styles.ts
import styled from "styled-components";

const MOBILE_BREAKPOINT = "480px";

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
`;

/**
 * カードは PC 表示時は横並び、モバイル時は縦並びになります。
 */
export const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    align-items: start;
    text-align: left;
  }
`;

export const Thumbnail = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 80px;
    height: 80px;
  }
`;

export const Details = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 100%;
  }
`;

export const ShowName = styled.h2`
  font-size: 1.3rem;
  margin: 0 0 0.5rem 0;
  color: #333;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 1rem;
  }
`;

export const ShowDescription = styled.p`
  font-size: 0.95rem;
  color: #555;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 0.9rem;
  }
`;

export const SpotifyButton = styled.a`
  border: 1px solid #ccc;
  width: 260px;
  height: 28px;
  margin-top: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1db954;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #17a74a;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    width: 200px;
    height: 22px;
    margin-top: 0.5rem;
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-left: 12px;
  }
`;

export const TextContainer = styled.span`
  flex: 1;
  text-align: left;
  padding-left: 0.5rem;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 0.9rem;
    padding-left: 0.2rem;
  }
`;

export const MedalBadge = styled.div<{ rank: number }>`
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: ${({ rank }) => {
    switch (rank) {
      case 1:
        return "#FFD700"; // ゴールド
      case 2:
        return "#C0C0C0"; // シルバー
      case 3:
        return "#CD7F32"; // ブロンズ
      default:
        return "transparent";
    }
  }};
  color: #fff;
  font-weight: bold;
  padding: 5px 8px;
  border-radius: 50%;
  font-size: 0.9rem;
`;
