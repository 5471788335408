// src/components/AdSlot.tsx
import React from "react";
import { useLocation } from "react-router-dom";
import adsConfig from "../config/adsConfig";
import useDeviceType from "../hooks/useDeviceType";

interface AdSlotProps {
  slot: string; // "sidebar", "header", "ranking" など
  adIndex?: number; // 任意：特定の広告設定を指定するためのインデックス
}

const AdSlot: React.FC<AdSlotProps> = ({ slot, adIndex }) => {
  const { pathname } = useLocation();
  const deviceType = useDeviceType();

  // 指定したスロットの広告設定配列を取得
  const configArray = adsConfig[slot];
  if (!configArray || configArray.length === 0) return null;

  // adIndex が指定されている場合
  if (adIndex !== undefined) {
    const config = configArray[adIndex];
    // ページ表示制御
    if (config.excludedPages && config.excludedPages.includes(pathname)) {
      return null;
    }
    if (config.showOnPages && !config.showOnPages.includes(pathname)) {
      return null;
    }
    const activeProvider = config.activeProvider;
    const providerConfig = config.providers[activeProvider];
    let adCode = providerConfig.code || "";
    if (providerConfig.codeDesktop && providerConfig.codeMobile) {
      adCode =
        deviceType === "mobile"
          ? providerConfig.codeMobile
          : providerConfig.codeDesktop;
    }
    return (
      <div
        className={`ad-block ad-block-${slot}-${adIndex}`}
        style={{ margin: "1rem 0" }}
      >
        <div dangerouslySetInnerHTML={{ __html: adCode }} />
      </div>
    );
  }

  // adIndex が指定されていない場合は全てループで表示（従来の実装）
  return (
    <>
      {configArray.map((config, index) => {
        if (config.excludedPages && config.excludedPages.includes(pathname)) {
          return null;
        }
        if (config.showOnPages && !config.showOnPages.includes(pathname)) {
          return null;
        }
        const activeProvider = config.activeProvider;
        const providerConfig = config.providers[activeProvider];
        let adCode = providerConfig.code || "";
        if (providerConfig.codeDesktop && providerConfig.codeMobile) {
          adCode =
            deviceType === "mobile"
              ? providerConfig.codeMobile
              : providerConfig.codeDesktop;
        }
        return (
          <div
            key={index}
            className={`ad-block ad-block-${slot}-${index}`}
            style={{ margin: "1rem 0" }}
          >
            <div dangerouslySetInnerHTML={{ __html: adCode }} />
          </div>
        );
      })}
    </>
  );
};

export default AdSlot;
