// src/components/LoadingIndicator.tsx
import React from "react";
import styled, { keyframes } from "styled-components";

interface LoadingIndicatorProps {
  /** 全ページを覆うかどうか */
  isFullPage?: boolean;
  /** サイズ(直径) */
  size?: number; // px
  /** 色 */
  color?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  isFullPage = false,
  size = 40,
  color,
}) => {
  return (
    <Overlay isFullPage={isFullPage}>
      <Spinner size={size} color={color} />
    </Overlay>
  );
};

export default LoadingIndicator;

// アニメーション定義
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Overlay = styled.div<{ isFullPage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isFullPage }) =>
    isFullPage
      ? `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 9998;
      `
      : `
        position: relative;
        width: auto;
        height: auto;
      `}
`;

const Spinner = styled.div<{ size: number; color?: string }>`
  border: ${({ size }) => size / 8}px solid #f3f3f3;
  border-top: ${({ size, color, theme }) =>
    `${size / 8}px solid ${
      color ? (theme.colors as any)[color] || color : theme.colors.primary
    }`};
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: ${spin} 1s linear infinite;
`;

// 全画面ローディング
//<LoadingIndicator isFullPage />

// カード内などの部分ローディング
//<div style={{ position: 'relative', width: '300px', height: '200px' }}>
//  <LoadingIndicator />
//  {/* 中身 */}
//</div>

// カラーやサイズのカスタマイズ
//<LoadingIndicator color="danger" size={80} isFullPage />
