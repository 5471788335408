// src/pages/ShowDetail/ShowDetail.tsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchAdminEpisodes,
  Episode,
} from "../../services/adminEpisodeService";
import { fetchAdminShow, Show } from "../../services/adminShowService";
import {
  Container,
  EpisodeCard,
  EpisodeImage,
  EpisodeDetails,
  EpisodeTitle,
  EpisodeDescription,
  ToggleSortButton,
} from "./ShowDetail.styles";
import SocialButton from "../../components/SocialButton";
import { FaSortAmountUp, FaSortAmountDown } from "react-icons/fa";

type SortOrder = "new" | "old";
const SortUpIcon = FaSortAmountUp as React.FC;
const SortDownIcon = FaSortAmountDown as React.FC;

const ShowDetail: React.FC = () => {
  const { showId } = useParams<{ showId: string }>();
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [show, setShow] = useState<Show | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<SortOrder>("new");

  useEffect(() => {
    if (!showId) {
      setLoading(false);
      return;
    }
    const numericShowId = parseInt(showId, 10);
    const loadData = async () => {
      try {
        const episodesData: Episode[] = await fetchAdminEpisodes(numericShowId);
        setEpisodes(episodesData);
        const showData: Show = await fetchAdminShow(numericShowId);
        setShow(showData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setEpisodes([]);
        setShow(null);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [showId]);

  if (!showId) return <p>Error: showId is missing.</p>;
  if (loading) return <p>Loading episodes...</p>;

  // API の返す並び順に応じて並べ替え
  const sortedEpisodes =
    sortOrder === "new" ? [...episodes].reverse() : episodes;

  return (
    <Container>
      <h1>{show ? show.name : `Show ${showId}`}</h1>
      <ToggleSortButton
        onClick={() => setSortOrder((prev) => (prev === "new" ? "old" : "new"))}
      >
        {sortOrder === "new" ? <SortUpIcon /> : <SortDownIcon />}
        {sortOrder === "new" ? "Show Oldest First" : "Show Newest First"}
      </ToggleSortButton>

      {sortedEpisodes.length > 0 ? (
        sortedEpisodes.map((ep) => (
          <EpisodeCard key={ep.id}>
            <EpisodeImage src={ep.thumbnail_url} alt={ep.name} />
            <EpisodeDetails>
              <EpisodeTitle>{ep.name}</EpisodeTitle>
              <EpisodeDescription>{ep.description}</EpisodeDescription>
              <SocialButton
                provider="spotify"
                as="a"
                href={ep.external_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Listen on Spotify
              </SocialButton>
            </EpisodeDetails>
          </EpisodeCard>
        ))
      ) : (
        <p>No episodes found.</p>
      )}
    </Container>
  );
};

export default ShowDetail;
