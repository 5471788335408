// src/services/rankingService.ts
import { RankingCreatePayload } from "../types/ranking";
import getOrCreateAnonymousUUID from "../utils/getOrCreateAnonymousUUID";

export interface Episode {
  id: number;
  name: string;
  release_date: string;
  thumbnail_url: string;
  external_url: string;
  duration_ms: number;
  show_name?: string;
  show_publisher?: string;
  description?: string;
}

export interface RankingEpisode {
  id: number;
  order_index: number;
  episode: Episode;
}

export interface RankingTag {
  id: number;
  tag: {
    id: number;
    name: string;
  };
}

export interface Ranking {
  id: number;
  title: string;
  description: string;
  creator: string;
  ranking_episodes: Episode[];
  like_count: number;
  ranking_tags: string[];
  created_at: string;
  is_own?: boolean;
  has_liked?: boolean;
}
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

export const fetchRankings = async (
  search?: string,
  ordering?: string
): Promise<Ranking[]> => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (ordering) params.append("ordering", ordering);

  const anonUuid = localStorage.getItem("anonymous_uuid");
  if (anonUuid) {
    params.append("anonymous_uuid", anonUuid);
  }

  // ▼ 追加
  const token = localStorage.getItem("auth_token");
  const headers: Record<string, string> = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await fetch(
    `${API_BASE_URL}/api/rankings/?${params.toString()}`,
    {
      method: "GET",
      credentials: "include",
      headers, // ← ここでトークンを送る
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch rankings");
  }
  return response.json();
};

export const createRanking = async (
  payload: RankingCreatePayload
): Promise<any> => {
  // ユーザーがログインしている場合は Authorization ヘッダーを付与
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  const token = localStorage.getItem("auth_token");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await fetch(`${API_BASE_URL}/api/rankings/create/`, {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error("Failed to create ranking");
  }
  return response.json();
};

/** ランキングに「いいね」するAPI呼び出し */
export interface LikeRankingResponse {
  detail: string;
  like_count: number;
  has_liked: boolean;
}

export async function likeRanking(
  rankingId: number
): Promise<LikeRankingResponse> {
  const payload: any = {};

  if (!isUserLoggedIn()) {
    payload.anonymous_uuid = getOrCreateAnonymousUUID();
  }

  const token = localStorage.getItem("auth_token"); // JWT
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await fetch(
    `${API_BASE_URL}/api/rankings/${rankingId}/like/`,
    {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify(payload),
    }
  );

  if (!response.ok) {
    const errData = await response.json();
    throw new Error(errData.detail || "Failed to like ranking");
  }

  const data = await response.json();
  return data as LikeRankingResponse; // こうすると明示的に型を合わせやすい
}

// --- 関数の外側に置く ---
function isUserLoggedIn(): boolean {
  return !!localStorage.getItem("auth_token");
}
