// src/pages/NotFound.tsx
import React from "react";

const NotFound: React.FC = () => {
  return (
    <div>
      <h2>404 Not Found</h2>
      <p>ページが見つかりません。</p>
    </div>
  );
};

export default NotFound;
