import styled from "styled-components";

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
`;

export const RankingCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
`;

export const RankingTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
`;

export const Creator = styled.p`
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.5rem;
`;

export const LikeCount = styled.p`
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 0.5rem;
`;

export const RankingTags = styled.div`
  margin-top: 0.5rem;
`;

export const Tag = styled.span`
  margin-right: 0.5rem;
  color: #007bff;
  font-size: 0.9rem;
`;

export const LoadMoreButton = styled.button`
  display: block;
  width: 200px;
  margin: 2rem auto;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
