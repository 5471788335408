import styled from "styled-components";

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
`;

export const SearchContainer = styled.form`
  display: flex;
  margin-bottom: 1rem;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
`;

export const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: #1ed760; /* テーマのprimary色 */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #17a74a;
  }
`;

export const ToggleSortButton = styled.button`
  display: inline-flex;
  align-items: center;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: 1rem;
  &:hover {
    background: #0056b3;
  }
  svg {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
`;

// カード全体を左右に並べる
export const ShowCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

// 画像は固定サイズにしてオブジェクトフィットで切り抜く
export const ShowImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
`;

// 詳細情報部分
export const ShowDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// タイトルはやや大きめに
export const ShowTitle = styled.h3`
  margin: 0;
  font-size: 1.3rem;
  color: #333;
`;

// 説明は一定文字数を超えた場合は "..." で省略（必要に応じて max-width を調整）
export const ShowDescription = styled.p`
  margin: 0;
  font-size: 0.95rem;
  color: #555;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

// 放送局情報
export const Publisher = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: #777;
`;

// 更新日
export const UpdatedDate = styled.p`
  margin: 0;
  font-size: 0.85rem;
  color: #999;
`;

// 読み込みボタン
export const LoadMoreButton = styled.button`
  display: block;
  width: 200px;
  margin: 2rem auto;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.primary || "#007bff"};
  color: ${({ theme }) => theme.colors.white || "#fff"};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius?.md || "8px"};
  cursor: pointer;
  transition: background-color 0.3s;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.secondary || "#0056b3"};
  }
`;
