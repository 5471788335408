import React from "react";
import styled from "styled-components";

interface ToggleButtonProps {
  isOn: boolean;
  onToggle: () => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ isOn, onToggle }) => {
  return (
    <Switch onClick={onToggle} isOn={isOn}>
      <Slider isOn={isOn} />
    </Switch>
  );
};

export default ToggleButton;

const Switch = styled.div<{ isOn: boolean }>`
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: ${({ isOn }) => (isOn ? "blue" : "#ccc")};
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const Slider = styled.div<{ isOn: boolean }>`
  position: absolute;
  top: 2px;
  left: ${({ isOn }) => (isOn ? "26px" : "2px")};
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #fff;
  transition: left 0.3s;
`;
