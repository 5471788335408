// src/pages/ShowEpisodes/ShowEpisodesPage.tsx
import React from "react";
import Layout from "../Layout/Layout";
import ShowEpisodes from "./ShowEpisodes";

const ShowEpisodesPage: React.FC = () => {
  return (
    <Layout>
      <ShowEpisodes />
    </Layout>
  );
};

export default ShowEpisodesPage;
