import React from "react";
import {
  Container,
  Title,
  Section,
  Paragraph,
  List,
} from "./TermsOfService.styles";

const TermsOfService: React.FC = () => {
  return (
    <Container>
      <Title>利用規約</Title>

      <Section>
        <Paragraph>
          この利用規約（以下「本規約」といいます。）は、Emine（笑音）（以下「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用する全てのユーザーは、本規約に同意したものとみなされます。
        </Paragraph>
      </Section>

      <Section>
        <h2>第1条（適用）</h2>
        <Paragraph>
          本規約は、本サービスの利用に関する一切の関係に適用されるものとします。
        </Paragraph>
      </Section>

      <Section>
        <h2>第2条（サービス内容）</h2>
        <Paragraph>
          本サービスは、Spotifyのお笑い芸人のラジオランキングをユーザーが作成・閲覧するためのプラットフォームを提供します。
        </Paragraph>
      </Section>

      <Section>
        <h2>第3条（データの取得）</h2>
        <Paragraph>
          本サービスは、Spotify
          APIを利用し、日本のお笑い芸人の番組および全エピソードの情報を取得・更新します。取得したデータはランキングの作成や検索に利用されます。
        </Paragraph>
      </Section>

      <Section>
        <h2>第4条（ユーザー認証）</h2>
        <Paragraph>
          ユーザーは、ログイン不要でランキング作成やいいねが可能です。必要に応じてLINE、Google、Spotifyなどのソーシャルログインもご利用いただけます。
        </Paragraph>
      </Section>

      <Section>
        <h2>第5条（ランキングの作成・利用）</h2>
        <Paragraph>
          ユーザーは、エピソードを選択してランキングを作成し、他のユーザーと共有することができます。ランキングにはタイトル、説明、エピソードのリスト、いいね数などが表示されます。
        </Paragraph>
      </Section>

      <Section>
        <h2>第6条（禁止事項）</h2>
        <Paragraph>ユーザーは、以下の行為を行ってはなりません：</Paragraph>
        <List>
          <li>不正アクセスまたは不正利用</li>
          <li>著作権その他第三者の権利を侵害する行為</li>
          <li>その他本サービスが不適切と判断する行為</li>
        </List>
      </Section>

      <Section>
        <h2>第7条（免責事項）</h2>
        <Paragraph>
          本サービスは、取得したデータの正確性や内容について保証致しません。ユーザーは自己責任で本サービスをご利用いただくものとします。
        </Paragraph>
      </Section>

      <Section>
        <h2>第8条（サービスの変更・中断）</h2>
        <Paragraph>
          本サービスは、予告なく内容の変更、停止または中断される場合があります。
        </Paragraph>
      </Section>

      <Section>
        <h2>第9条（利用規約の変更）</h2>
        <Paragraph>
          本規約は、必要に応じて変更されることがあります。変更後の規約は、本サービスに掲載された時点で効力を生じるものとします。
        </Paragraph>
      </Section>

      <Section>
        <h2>第10条（準拠法・裁判管轄）</h2>
        <Paragraph>
          本規約の解釈及び適用については日本法に準拠し、本サービスに関して紛争が生じた場合は東京地方裁判所を専属的合意管轄裁判所とします。
        </Paragraph>
      </Section>

      <Section>
        <h2>第11条（広告の表示について）</h2>
        <Paragraph>
          本サービスは、Google、A8、またはその他の広告配信サービスの広告を掲載しており、これにより収益を得る場合があります。広告内容は、ユーザーの興味関心に基づいて表示されることがあります。
        </Paragraph>
      </Section>

      <Section>
        <Paragraph>（以上）</Paragraph>
      </Section>
    </Container>
  );
};

export default TermsOfService;
