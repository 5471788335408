import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import AdSlot from "./AdSlot";

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 0.5rem;
    height: auto;
  }
`;

// ヘッダーの高さ分の余白を上部に確保
const AffiliateNotice = styled.div`
  margin-top: 120px; /* ヘッダーの高さ分 */
  background: #f8f8f8;
  color: #555;
  padding: 0.5rem 2rem;
  text-align: center;
  font-size: 0.9rem;
  border-bottom: 1px solid #e0e0e0;
  @media (max-width: 768px) {
    margin-top: 83px;
    font-size: 0.6rem;
    padding: 0.5rem 1rem;
  }
`;

const Logo = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
`;

const Nav = styled.nav`
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
  margin-right: 5rem;
`;

const NavItem = styled.li`
  a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    white-space: nowrap;
  }
`;

const Hamburger = styled.div`
  display: none;
  font-size: 2.3rem;
  cursor: pointer;
  @media (max-width: 768px) {
    display: block;
    margin-right: 2rem;
  }
`;

const MobileMenu = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background: #ffffff;
  padding: 2rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(${(props) => (props.open ? "0" : "100%")});
  transition: transform 0.3s ease-in-out;
  z-index: 1100;
  overflow-y: auto;
`;

const MobileCloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.8rem;
  background: none;
  border: none;
  cursor: pointer;
`;

const MobileNavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
  padding: 0;
`;

const MobileNavItem = styled.li`
  a {
    text-decoration: none;
    color: #333;
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
  }
`;

const LogoImage = styled.img`
  max-width: 200px;
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    max-width: 150px;
  }
`;

const Header: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    setIsLoggedIn(!!token);
  }, []);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  return (
    <>
      <HeaderContainer>
        <Logo>
          <a href="/ranking">
            <LogoImage src={logo} alt="ロゴ" />
          </a>
        </Logo>
        <Nav>
          <NavList>
            <NavItem>
              <Link to="/ranking">ランキング一覧</Link>
            </NavItem>
            <NavItem>
              <Link to="/ranking/create">ランキング作成</Link>
            </NavItem>
            <NavItem>
              <Link to="/shows/popular">人気の番組</Link>
            </NavItem>
            <NavItem>
              <Link to="/contact">お問い合わせ</Link>
            </NavItem>
            {!isLoggedIn ? (
              <>
                <NavItem>
                  <Link to="/register">新規登録</Link>
                </NavItem>
                <NavItem>
                  <Link to="/login">ログイン</Link>
                </NavItem>
              </>
            ) : (
              <NavItem>
                <Link to="/user-settings">ユーザー設定</Link>
              </NavItem>
            )}
          </NavList>
        </Nav>
        <Hamburger onClick={toggleMenu}>☰</Hamburger>
      </HeaderContainer>

      {/* ヘッダー直下に、ヘッダーの高さ分の余白を設けた上で注意文を表示 */}
      <AffiliateNotice>
        ※本サイトは、広告やアフィリエイトリンクを含む可能性があります。
      </AffiliateNotice>

      <MobileMenu open={menuOpen}>
        <MobileCloseButton onClick={toggleMenu}>×</MobileCloseButton>
        <MobileNavList>
          <MobileNavItem>
            <Link to="/ranking" onClick={toggleMenu}>
              ランキング一覧
            </Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to="/ranking/create" onClick={toggleMenu}>
              ランキング作成
            </Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to="/shows/popular" onClick={toggleMenu}>
              人気の番組
            </Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to="/contact" onClick={toggleMenu}>
              お問い合わせ
            </Link>
          </MobileNavItem>
          {!isLoggedIn ? (
            <>
              <MobileNavItem>
                <Link to="/register" onClick={toggleMenu}>
                  新規登録
                </Link>
              </MobileNavItem>
              <MobileNavItem>
                <Link to="/login" onClick={toggleMenu}>
                  ログイン
                </Link>
              </MobileNavItem>
            </>
          ) : (
            <MobileNavItem>
              <Link to="/user-settings" onClick={toggleMenu}>
                ユーザー設定
              </Link>
            </MobileNavItem>
          )}
        </MobileNavList>
      </MobileMenu>
      <AdSlot slot="header" />
    </>
  );
};

export default Header;
