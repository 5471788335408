import styled from "styled-components";

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;

  @media (max-width: 768px) {
    padding: 1.4rem;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Section = styled.section`
  margin-bottom: 24px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;

  li {
    margin-bottom: 8px;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;
