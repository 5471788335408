// src/components/Button.tsx
import React from "react";
import styled, { css } from "styled-components";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** ボタン色をテーマのkeyか、カスタムカラーコードで指定 */
  bgColor?: string;
  /** 文字色をテーマのkeyか、カスタムカラーコードで指定 */
  textColor?: string;
  /** 角丸にするかどうか */
  rounded?: boolean;
  /** 幅(pxや%)やheightを指定可能に (例: '200px', '100%', etc.) */
  width?: string;
  height?: string;
  border?: string;
  as?: React.ElementType;
}

/**
 * Button - 汎用ボタンコンポーネント
 * - デフォルト色や角丸をthemeから読みつつ、propsで自由に上書き可
 */
const Button: React.FC<ButtonProps> = ({
  children,
  bgColor,
  textColor,
  rounded,
  width,
  height,
  border,
  className,
  ...rest
}) => {
  return (
    <StyledButton
      bgColor={bgColor}
      textColor={textColor}
      rounded={rounded}
      width={width}
      height={height}
      border={border}
      className={className}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default Button;

// styled-components
const StyledButton = styled.button<ButtonProps>`
  /* デフォルトの色 (theme.primary) */
  background-color: ${({ theme, bgColor }) =>
    bgColor ? (theme.colors as any)[bgColor] || bgColor : theme.colors.primary};

  /* テキスト色(デフォルトは白) */
  color: ${({ theme, textColor }) =>
    textColor
      ? (theme.colors as any)[textColor] || textColor
      : theme.colors.white};

  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.6rem 1.2rem;
  font-weight: 600;
  /* 角丸の有無 */
  border-radius: ${({ theme, rounded }) =>
    rounded ? theme.borderRadius.md : theme.borderRadius.none};

  /* width / height */
  ${({ width, height }) => css`
    width: ${width || "auto"};
    height: ${height || "auto"};
  `}

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

// 例: Button使用例
// <Button>Default</Button>
// <Button bgColor="success" rounded>Rounded Green</Button>
// <Button bgColor="#FF00FF" width="200px">Custom Color</Button>
// <Button textColor="#333" bgColor="warning">Warning</Button>
