// src/pages/AdminShows/AdminShows.tsx
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  ShowCard,
  ShowImage,
  ShowDetails,
  ShowTitle,
  ShowDescription,
  Publisher,
  UpdatedDate,
  ToggleSortButton,
  SearchContainer,
  SearchInput,
} from "./AdminShows.styles";
import {
  fetchAdminShows,
  deleteAdminShow,
  Show,
} from "../../services/adminShowService";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

type SortOrder = "new" | "old";

const AdminShows: React.FC = () => {
  const [shows, setShows] = useState<Show[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortOrder>("new");
  const [searchQuery, setSearchQuery] = useState<string>("");

  // 削除モーダル用の state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedShow, setSelectedShow] = useState<Show | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  // ページ番号を useRef で管理
  const pageRef = useRef<number>(1);
  // 現在の検索・並べ替え条件用トークン
  const requestTokenRef = useRef<number>(0);
  // Intersection Observer の ref
  const observer = useRef<IntersectionObserver | null>(null);

  // ordering: "new"なら新着順（-created_at）、"old"なら古い順（created_at）
  const ordering = sortOrder === "new" ? "-created_at" : "created_at";

  // loadShows を useCallback でラップして依存関係を明示
  const loadShows = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    // 現在の条件用トークンをキャプチャ
    const currentToken = requestTokenRef.current;
    try {
      const data = await fetchAdminShows(
        pageRef.current,
        10,
        searchQuery,
        ordering
      );
      // リクエスト開始時と条件が変わっていたら結果を無視
      if (currentToken !== requestTokenRef.current) {
        return;
      }
      if (data.length < 10) {
        setHasMore(false);
      }
      setShows((prev) => {
        const newData = data.filter(
          (item) => !prev.some((prevItem) => prevItem.id === item.id)
        );
        return [...prev, ...newData];
      });
      pageRef.current += 1;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [loading, searchQuery, ordering]);

  // 条件（searchQuery, sortOrder）が変わったらリセットして再取得
  useEffect(() => {
    requestTokenRef.current += 1;
    setShows([]);
    setHasMore(true);
    pageRef.current = 1;
    loadShows();
  }, [sortOrder, searchQuery, loadShows]);

  // Infinite Scroll 用の Intersection Observer
  const lastShowElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore && !loading) {
            loadShows();
          }
        },
        {
          rootMargin: "100px",
        }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, loadShows]
  );

  // 検索フォームの送信処理（今回は不要なので preventDefault するだけ）
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
  };

  // 削除モーダルを開く
  const openDeleteModal = (show: Show) => {
    setSelectedShow(show);
    setIsModalOpen(true);
  };

  // モーダルを閉じる
  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setSelectedShow(null);
  };

  // 番組削除処理
  const handleDelete = async () => {
    if (!selectedShow) return;
    setDeleteLoading(true);
    try {
      await deleteAdminShow(selectedShow.id);
      setShows((prev) => prev.filter((s) => s.id !== selectedShow.id));
    } catch (err) {
      console.error(err);
      // エラー通知などの処理を追加するとより良いでしょう
    } finally {
      setDeleteLoading(false);
      closeDeleteModal();
    }
  };

  return (
    <Container>
      <h1>Admin Shows</h1>
      {/* 検索窓 */}
      <SearchContainer onSubmit={handleSearch}>
        <SearchInput
          type="text"
          placeholder="Search by name, description, publisher..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchContainer>
      <ToggleSortButton
        onClick={() => setSortOrder((prev) => (prev === "new" ? "old" : "new"))}
      >
        {sortOrder === "new" ? "Show Oldest First" : "Show Newest First"}
      </ToggleSortButton>
      {shows.map((show, index) => {
        const handleClick = () => {
          navigate(`/admin/shows/${show.id}`);
        };
        const cardContent = (
          <>
            <ShowImage src={show.thumbnail_url} alt={show.name} />
            <ShowDetails>
              <ShowTitle>{show.name}</ShowTitle>
              <ShowDescription>{show.description}</ShowDescription>
              <Publisher>放送局: {show.publisher}</Publisher>
              <UpdatedDate>
                更新日:{" "}
                {new Date(show.last_spotify_update).toLocaleDateString()} <br />
                データベース作成日: {show.created_at}
                <br />
                データベース更新日: {show.updated_at}
              </UpdatedDate>
            </ShowDetails>
            {/* 削除ボタン：カードクリックによる詳細画面遷移を防ぐために event.stopPropagation() を実施 */}
            <Button
              bgColor="danger"
              onClick={(e) => {
                e.stopPropagation();
                openDeleteModal(show);
              }}
            >
              削除
            </Button>
          </>
        );
        if (index === shows.length - 1) {
          return (
            <ShowCard
              key={show.id}
              ref={lastShowElementRef}
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            >
              {cardContent}
            </ShowCard>
          );
        } else {
          return (
            <ShowCard
              key={show.id}
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            >
              {cardContent}
            </ShowCard>
          );
        }
      })}
      {loading && <p>Loading...</p>}
      <Modal isOpen={isModalOpen} onClose={closeDeleteModal}>
        <h2>番組削除の確認</h2>
        <p>
          本当に番組 {selectedShow?.name} を削除してもよろしいですか？
          <br />※ この操作により、当該番組に紐づくエピソードも削除されます。
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button onClick={closeDeleteModal}>キャンセル</Button>
          <Button
            bgColor="danger"
            onClick={handleDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? "削除中..." : "削除"}
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default AdminShows;
