// src/App.tsx
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import RankingListPage from "./pages/RankingList/RankingListPage";
import RankingCreation from "./pages/RankingCreation/RankingCreation";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminShows from "./pages/AdminShows/AdminShows";
import AdminUsers from "./pages/AdminUsers/AdminUsers";
import AdminRankings from "./pages/AdminRankings/AdminRankings";
import NotFound from "./pages/NotFound/NotFound";
import AdminRoute from "./routes/AdminRoute";
import ShowDetail from "./pages/ShowDetail/ShowDetail";
import ShowEpisodesPage from "./pages/ShowEpisodes/ShowEpisodesPage";
import FrontLayout from "./layouts/FrontLayout";
import FrontLayoutWithScroll from "./layouts/FrontLayoutWithScroll";
import SocialLoginCallback from "./pages/SocialLoginCallback/SocialLoginCallback";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import UserSettings from "./pages/UserSettings/UserSettings";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import PopularShowsPage from "./pages/PopularShows/PopularShowsPage";
import ScrollToTop from "./components/ScrollToTop";
import ContactForm from "./pages/ContactForm/ContactForm";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import "./styles/ads.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* ヘッダーを追加するレイアウト */}
        <Route element={<FrontLayout />}>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/user-settings"
            element={
              <PrivateRoute>
                <UserSettings />
              </PrivateRoute>
            }
          />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactForm />} />
        </Route>

        {/* ヘッダーとトップスクロールボタンを追加するレイアウト */}
        <Route element={<FrontLayoutWithScroll />}>
          <Route path="/" element={<Navigate to="/ranking" />} />
          <Route path="/ranking" element={<RankingListPage />} />
          <Route path="/ranking/create" element={<RankingCreation />} />
          <Route path="/shows/:showId" element={<ShowEpisodesPage />} />
          <Route path="/shows/popular" element={<PopularShowsPage />} />
        </Route>
        {/* Admin 専用ルートは FrontLayout 外でヘッダー非表示 */}
        <Route path="/social-login" element={<SocialLoginCallback />} />

        <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          path="/admin"
          element={
            <AdminRoute>
              <AdminDashboard />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/shows"
          element={
            <AdminRoute>
              <AdminShows />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/shows/:showId"
          element={
            <AdminRoute>
              <ShowDetail />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <AdminRoute>
              <AdminUsers />
            </AdminRoute>
          }
        />
        <Route
          path="/admin/rankings"
          element={
            <AdminRoute>
              <AdminRankings />
            </AdminRoute>
          }
        />
        {/* 404 ページ */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
