import React, { useEffect, useState } from "react";
import {
  Container,
  UserCard,
  UserInfo,
  LoadMoreButton,
} from "./AdminUsers.styles";
import {
  fetchAdminUsers,
  deleteAdminUser,
  AdminUser,
} from "../../services/adminUserService";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

const AdminUsers: React.FC = () => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  // 削除モーダル用の state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<AdminUser | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const data = await fetchAdminUsers(page, 10);
      if (data.length < 10) {
        setHasMore(false);
      }
      setUsers((prev) => [...prev, ...data]);
      setPage(page + 1);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 削除モーダルを開く
  const openDeleteModal = (user: AdminUser) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  // モーダルを閉じる
  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  // ユーザー削除処理
  const handleDelete = async () => {
    if (!selectedUser) return;
    setDeleteLoading(true);
    try {
      await deleteAdminUser(selectedUser.id);
      setUsers((prev) => prev.filter((u) => u.id !== selectedUser.id));
    } catch (err) {
      console.error(err);
      // エラー通知等の処理を追加するとより良いです
    } finally {
      setDeleteLoading(false);
      closeDeleteModal();
    }
  };

  return (
    <Container>
      {users.map((user) => (
        <UserCard key={user.id}>
          <UserInfo>
            <div>
              <strong>{user.username || `User(${user.id})`}</strong>
            </div>
            <div>Type: {user.user_type}</div>
            <div>登録日: {new Date(user.created_at).toLocaleDateString()}</div>
          </UserInfo>
          <Button bgColor="danger" onClick={() => openDeleteModal(user)}>
            削除
          </Button>
        </UserCard>
      ))}
      {hasMore && (
        <LoadMoreButton onClick={loadUsers} disabled={loading}>
          {loading ? "Loading..." : "もっと見る"}
        </LoadMoreButton>
      )}
      <Modal isOpen={isModalOpen} onClose={closeDeleteModal}>
        <h2>ユーザー削除の確認</h2>
        <p>
          本当にユーザー {selectedUser?.username || `User(${selectedUser?.id})`}{" "}
          を削除してもよろしいですか？
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button onClick={closeDeleteModal}>キャンセル</Button>
          <Button
            bgColor="danger"
            onClick={handleDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? "削除中..." : "削除"}
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default AdminUsers;
