// src/contexts/userContext.tsx
import React, { createContext, useState, useEffect } from "react";
import { authService } from "../services/authService";

interface UserContextValue {
  user: any | null;
  token: string | null;
  loading: boolean;
  setUser: React.Dispatch<React.SetStateAction<any | null>>;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const UserContext = createContext<UserContextValue>({
  user: null,
  token: null,
  loading: true,
  setUser: () => {},
  setToken: () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // ユーザー情報
  const [user, setUser] = useState<any | null>(null);
  // localStorage から初期トークンを読込
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("auth_token")
  );
  // ローディング状態
  const [loading, setLoading] = useState(true);

  /**
   * token が変わるたび、currentUser を取得し user を更新
   *  - token が無ければ未ログイン状態
   *  - token があれば authService.getCurrentUser() を呼ぶ
   */
  useEffect(() => {
    if (!token) {
      // 未ログイン状態
      setUser(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    authService
      .getCurrentUser()
      .then((fetchedUser) => {
        // fetchedUser がサーバーのレスポンス形状により
        // { user: {...}, access: "...", refresh: "..." } かもしれない
        // ここでは user に相当する部分をセット
        setUser(fetchedUser.user || fetchedUser);
      })
      .catch((err) => {
        console.error("Failed to fetch current user:", err);
        setUser(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        loading,
        setUser,
        setToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
