// src/pages/AdminLogin/AdminLogin.tsx

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";

const AdminLogin: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // UserContext にある setToken / setUser を取得
  const { setToken, setUser } = useContext(UserContext);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // 管理者ログイン用エンドポイント（例: /api/admin/login/）へリクエスト
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/login/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ username, password }),
        }
      );

      if (!response.ok) {
        const errText = await response.text();
        throw new Error(errText || "Admin login failed");
      }

      // 1度だけ JSON ボディを取り出す
      const data = await response.json();
      // Django 側レスポンス例:
      // {
      //   "access": "...",
      //   "refresh": "...",
      //   "user": { "id": 1, "username": null, "is_staff": true, ... }
      // }

      // ★ 「refresh」 も必ず保存
      localStorage.setItem("auth_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);

      // Context 側の token & user に即座にセット
      setToken(data.access);
      setUser(data.user);

      // 管理ページへ移動
      navigate("/admin");
    } catch (err: any) {
      setError(err.message || "Login failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "auto", padding: "2rem" }}>
      <h2>Admin Login</h2>
      {error && (
        <div style={{ color: "red", marginBottom: "1rem" }}>{error}</div>
      )}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "1rem" }}>
          <label htmlFor="username" style={{ display: "block" }}>
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            style={{ width: "100%", padding: "0.5rem" }}
          />
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <label htmlFor="password" style={{ display: "block" }}>
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ width: "100%", padding: "0.5rem" }}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: "0.5rem 1rem",
            cursor: loading ? "not-allowed" : "pointer",
          }}
        >
          {loading ? "Logging in..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
